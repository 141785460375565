import GridOnIcon from '@mui/icons-material/GridOn';
import { Box, Divider } from '@mui/material';
import GraphIcon from 'assets/GraphIcon';
import ListIcon from 'assets/ListIcon';
import ButtonIcon from 'components/ButtonIcon';
import ButtonIconSvg from 'components/ButtonIconSvg';

const RevisionFilter = ({ changeView, view, isGraphView, isGraphAllowed }: any) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: "5px"
			}}
		>
			{view == 'Table' && (
				<>
					<ButtonIconSvg isActive={isGraphView} onClick={() => changeView('Graph')}>
						<GraphIcon />
					</ButtonIconSvg>
				</>
			)}
			{/* hide view table if graph is allowed */}
			{isGraphAllowed && (
				<>
					<ButtonIcon isActive={view == 'Table'} onClick={() => changeView('Table')} >
						<GridOnIcon sx={{ fontSize: '35px', m: 0.5 }} />
					</ButtonIcon>
					<Divider orientation="vertical" variant="middle" flexItem />
				</>
			)}
			<ButtonIconSvg isActive={view == 'List'} onClick={() => changeView('List')}>
				<ListIcon />
			</ButtonIconSvg>

		</Box>
	);
};

export default RevisionFilter;