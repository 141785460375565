import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Box, Typography } from '@mui/material';
import FileLogoBig from 'assets/file-icon-big.svg';
import FileLogo from 'assets/file-icon.svg';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';



const baseStyle = {
  cursor:"pointer",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '341px',
  width: '341px', 
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  padding: "1rem",
  transition: 'border .3s ease-in-out',
  textAlign: "center"
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const disabledStyle = {
  opacity: 0.5,
  pointerEvents: 'none',
};

const ImageDropZone = (props: any) => {
  const {
    preview,
    image_url,
    onImageDrop,
    onImageRemove,
    size,
    image_error,
    enabled,
    customStyle,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: any) => {
    if (enabled) {
      onImageDrop(acceptedFiles, size);
    }
  }, [enabled, onImageDrop, size]);

  const imageData = preview || image_url || null;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg'] },
    disabled: !enabled,
    maxSize: size
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          let message = '';
          switch (error.code) {
            case 'file-invalid-type':
              message = t('REVISION_FILE_INVALID_ERROR');
              break;
            case 'file-too-large':
              message = t('IMAGE_SIZE_ERROR');
              break;
            default:
              message = t('FILE_ERROR_GENERIC');
          }
          enqueueSnackbar(message, { variant: 'error' });
        });
      });
    }
  }, [fileRejections, enqueueSnackbar, t]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : { }),
      ...(!enabled ? disabledStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, enabled]
  );

  return !preview && !image_url ? (
    <div {...getRootProps({ style: { ...style, ...customStyle } } as any)}>
      <input {...getInputProps()} />
      {customStyle ? 
        <img src={FileLogo} alt='file' width={80} style={{ opacity: "0.2" }} /> : 
        <img src={FileLogoBig} alt='file' style={{ opacity: "0.2" }} />
      }
      <div style={{ paddingTop: "10px" }}>{t('DROPZONE_PLACEHOLDER')}</div>
      <Typography variant='caption' color='error'>
        {image_error}
      </Typography>
    </div>
  ) : (
    <div style={{ ...style, ...customStyle, position: 'relative', padding: 0, overflow: 'hidden' }}>
      {(image_url || preview) && (
        <img
          style={{ 
            objectFit: 'contain',
            width: customStyle ? '100%' : '341px',
            height: customStyle ? '170px' : '341px',
            display: 'block',
            borderRadius: 2
          }}
          src={imageData}
          alt='alt'
        />
      )}

      {enabled && onImageRemove && (
        <Box sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 1
        }}>
          <CloseSharpIcon
            onClick={(e) => {
              e.stopPropagation();
              onImageRemove(e);
            }}
            sx={{
              cursor: 'pointer',
              color: '#000',
              border: '1.5px black solid',
              borderRadius: '50%',
              p: "2px",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default ImageDropZone;