import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HtmlLanguageManager = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Mettre à jour l'attribut lang de la balise HTML
    document.documentElement.lang = i18n.language;

    // Mettre à jour le titre en fonction de la langue
    const title = i18n.language === 'he' ? 'דירוג אנרגטי' : 'Energy Rating';
    document.title = title;
  }, [i18n.language]);

  return null;
};

export default HtmlLanguageManager; 