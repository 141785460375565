import { axiosInstance } from "utils/AxiosInstance";

export const GetLayoutByProjectId = async (projectId: any) => {
  try {
    const response = await axiosInstance.get(`layout/${projectId}`);
    return response.data;
  } catch (error) {
    throw error; // On propage l'erreur au lieu de la retourner
  }
};

export const updateLayoutConfigByProjectId = async (projectId: any, layout: any) => {
  const response = await axiosInstance.put(`layout/${projectId}`, layout);
  return response.data;
};

export const resetLayout = async (projectId: string) => {
  const response = await axiosInstance.delete(`layout/${projectId}`);
  return response.data;
};