import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import strategyClient from 'utils/storage/strategyClient';
import { clientConfig } from 'utils/utils';
import {
  IAdminTopUp,
  IInviteUser,
  IOrganisationUpdate,
  Order,
} from './../utils/contracts';

export const updateOrganisation = async (orgUpdate: IOrganisationUpdate) => {
  if (orgUpdate.preview) {
    const uploaderService = strategyClient.getStrategy(
      'image',
      orgUpdate.id,
      5000
    );
    const path =
      clientConfig.cloudinaryFolder + '/organizations/' + orgUpdate.id + '/cover';

    let file = await uploaderService.uploadObject({
      publicId: path,
      id: orgUpdate.id,
      type: 'image',
      file: orgUpdate.preview,
      destination: path,
      size: { height: 280, width: 280 },
    } as any);
    orgUpdate.image_url = file.webContentLink;
  }

  return await axiosInstance.put(
    `${API_PATHS.UPDATE_BUSINESS_SETTINGS}${orgUpdate.id}`,
    {...orgUpdate,preview:orgUpdate.image_url}
  );
};

export const updateBusinessSetting = async (orgUpdate: IOrganisationUpdate) => {
  if (orgUpdate.preview) {
    const uploaderService = strategyClient.getStrategy(
      'image',
      orgUpdate.id,
      5000
    );
    const path =
      clientConfig.cloudinaryFolder + '/organizations/' + orgUpdate.id + '/cover';

    let file = await uploaderService.uploadObject({
      publicId: path,
      id: orgUpdate.id,
      type: 'image',
      file: orgUpdate.preview,
      destination: path,
      size: { height: 280, width: 280 },
    } as any);
    orgUpdate.image_url = file.webContentLink;
  }
  return await axiosInstance.put(
    `${API_PATHS.UPDATE_BUSINESS_SETTINGS}${orgUpdate.id}/business-setting`,
    {...orgUpdate,preview:orgUpdate.image_url}
  );
};


export const inviteMember = async (data: IInviteUser) => {
  return await axiosInstance.post(
    `${API_PATHS.INVITE_MEMBER_BUSINESS_SETTINGS}`,
    data
  );
};

export const resendInvitation = async (invitationId: any) => {
  return await axiosInstance.patch(`/invites/${invitationId}/resend`);
};

export const removeStaffbyInvitationID = async (invitationId: any) => {
  return await axiosInstance.delete(`/users/${invitationId}/kick`);
};

export const canclebyInvitationID = async (invitationId: any) => {
  return await axiosInstance.delete(`/invites/${invitationId}`);
};

export const addOrganisation = async (orgUpdate: any) => {
  if (orgUpdate.preview) {
    const uploaderService = strategyClient.getStrategy(
      'image',
      orgUpdate.id,
      5000
    );
    const path =
     clientConfig.cloudinaryFolder + '/organizations/' + orgUpdate.id + '/cover';

    let file = await uploaderService.uploadObject({
      publicId: path,
      id: orgUpdate.id,
      type: 'image',
      file: orgUpdate.preview,
      destination: path,
      size: { height: 280, width: 280 },
    } as any);
    orgUpdate.image_url = file.webContentLink;
  }

  return await axiosInstance.post(`${API_PATHS.ADMIN_ADD_BUSINESS}`, orgUpdate);
};

export const adminTopUp = async (topupData: IAdminTopUp) => {
  return await axiosInstance.post(`${API_PATHS.ADMIN_TOP_UP}`, topupData, {
    headers: {
      'x-organization-id': topupData.OrganizationId ?? '',
    },
  });
};

export const updatePermission = async (
    userId: string,
    authorization: string,
    organizationId: string
) => {
    return await axiosInstance.put(
        `${API_PATHS.UPDATE_USER_PERMISSION}`,
        {
            userId,
            authorization,
            organizationId,
        }
    )
}

interface TransactionsQueryParams {
  page: number;
  pageSize: number;
  sortOrder: Order;
  sortBy: string;
}

export const getOrganisationTransactions = async (organizationId: string, query: TransactionsQueryParams) => {
  const response = await axiosInstance.post(`organizations/${organizationId}/transactions`, query);
  return response.data;
};
