import { SearchOutlined } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ClearIcon from '@mui/icons-material/Clear'; // Import du logo pour effacer
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import GridIcon2 from 'assets/GridIcon2';
import ListIcon from 'assets/ListIcon';

import ButtonIcon from 'components/ButtonIcon';
import ButtonIconSvg from 'components/ButtonIconSvg';
import ButtonIconText from 'components/ButtonIconText';
import Restrict from 'components/Restrict';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ProjectFilter = ({ changeView, handleSearch, handleClearSearch, view }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const [value, setValue] = useState("");

  const handleChangeSearchText = (e: any) => {
    setValue(e.target.value);
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    setValue("");
    handleClearSearch();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: "144px",
        mb: "22px",
        flexWrap: "wrap",
        gap: '1rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '2rem',
          flexWrap: "wrap",
          flex: 1
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '31px',
              color: '#2F3B49',
            }}
          >
            {t('PROJECTS_TITLE')}
          </Typography>
        </Box>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '1rem',
          flexWrap: 'nowrap'
        }}>
          <Restrict forbidden={['viewer']}>
            <ButtonIconText
              icon={<div style={{ fontSize: "40px", display: "flex", alignItems: "center" }}><AddRoundedIcon fontSize='inherit' /></div>}
              disableElevation={true}
              type='submit'
              variant='contained'
              size='large'
              onClick={(e: any) => {
                e.preventDefault();
                const url = `${businessId ? '/add-project/' + businessId : '/add-project'}`;
                navigate(url);
              }}>
              {t("PROJECTS_ADD_BUTTON")}
            </ButtonIconText>
          </Restrict>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '0.5rem',
              '& > *': {
                cursor: 'pointer',
              },
            }}
          >
            <ButtonIconSvg isActive={view === 'Grid'} onClick={() => changeView('Grid')}>
              <GridIcon2 />
            </ButtonIconSvg>
            <ButtonIconSvg isActive={view === 'Table'} onClick={() => changeView('Table')}>
              <ListIcon />
            </ButtonIconSvg>
          </Box>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '300px' } }}>
          <TextField
            type='text'
            fullWidth
            variant='outlined'
            placeholder={t('PROJECTS_SEARCH_PLACEHOLDER')}
            sx={{
              '& .MuiInputBase-root': {
                WebkitFontSmoothing: 'auto',
                padding: "4px",
              },
              '& .MuiInputBase-inputAdornedStart': {
                padding: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {value ? (
                    <ButtonIcon onClick={handleClear}>
                      <ClearIcon />
                    </ButtonIcon>
                  ) : (
                    <ButtonIcon disabled>
                      <SearchOutlined />
                    </ButtonIcon>
                  )}
                </InputAdornment>
              ),
              sx: { height: '50px' }
            }}
            value={value}
            onChange={handleChangeSearchText}
            autoComplete='off'
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '0.5rem',
          '& > *': {
            cursor: 'pointer',
          },
        }}
      >
        <ButtonIconSvg isActive={view === 'Grid'} onClick={() => changeView('Grid')}>
          <GridIcon2 />
        </ButtonIconSvg>
        <ButtonIconSvg isActive={view === 'Table'} onClick={() => changeView('Table')}>
          <ListIcon />
        </ButtonIconSvg>
      </Box>
    </Box>
  );
};

export default ProjectFilter;