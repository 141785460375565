import { Box } from "@mui/material";

export const levels = [
  {
    from: 4.51,
    to: 5.5,
    color: '#3F72B9',
    label: 'A+',
  },
  {
    from: 3.51,
    to: 4.5,
    color: '#10B3DF',
    label: 'A',
  },
  {
    from: 2.51,
    to: 3.5,
    color: '#0D7A3B',
    label: 'B',
  },
  {
    from: 1.51,
    to: 2.5,
    color: '#4FB947',
    label: 'C',
  },
  {
    from: 0.51,
    to: 1.5,
    color: '#FDC21F',
    label: 'D',
  },
  {
    from: -0.51,
    to: 0.5,
    color: '#F15025',
    label: 'E',
  },
  {
    from: -1.51,
    to: -0.5,
    color: '#B92126',
    label: 'F',
  },
];


export const getColor = (grade: string) => {
  const gradeColors = {
    'A+': '#3F72B9',
    'A': '#10B3DF',
    'B': '#0D7A3B',
    'C': '#4FB947',
    'D': '#FDC21F',
    'E': '#F15025',
    'F': '#B92126'
  };

  // Retourner la couleur correspondante ou la couleur par défaut (F)
  return gradeColors[grade] || gradeColors['F'];
};

export default function GradeBadge(props: any) {
  let { rating } = props;
  if (rating !== undefined) {
    let color = getColor(rating);
    return (
      <Box
      dir="ltr" 
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
        sx={{

          width: '36px',
          height:"36px",
          borderRadius:"10%",
          color: `white`,
          fontWeight:"600",
          backgroundColor: `${color}`,
        }}
      >{rating}</Box>
    );
  } else {
    return null;
  }
}
