import { IconButton, useTheme } from '@mui/material';


const ButtonIcon = ({ isActive, children, onClick, sx, disabled }: { isActive?: boolean; onClick?: (e:any) => void,children: React.ReactNode, sx?: object, disabled?: boolean }) => {
	const theme = useTheme();

	return (
		<IconButton
			onClick={disabled ? undefined : onClick}
			disabled={disabled}
			sx={{
				color: isActive ? theme.palette.primary.main : theme.palette.secondary.main, // Couleur de l'icône
				'&:hover': {
					color: disabled ? 'inherit' : (isActive ? theme.palette.primary.main : 'black'), // Pas de changement de couleur au survol si désactivé
				},
				...sx // Intégration de sx dans les styles
			}}
		>
			{children} 
			</IconButton>
	);
};

export default ButtonIcon;