import { Box, TablePagination } from '@mui/material';
import ProjectTileNew from 'components/ProjectTileNew';
import { useTranslation } from 'react-i18next';


const ProjectGridView = ({
  data,
  pagination,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  refetchingData,
  deleteProjecthandler,
}: any) => {
  const { t } = useTranslation();
  return (
    <Box className='main__content'>
      <Box className='items'>
        {data
          .map((data: any) => {
            return (
              <ProjectTileNew
                data={{ ...data, handleDeleteProject: deleteProjecthandler }}
                key={data.id}
                refetch={refetchingData}
              />
            );
          })}
      </Box>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        page={page - 1}
        count={pagination.total}
        onPageChange={(_, newPage) => onPageChange(newPage + 1)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => {
          const newPageSize = parseInt(e.target.value, 20);
          onPageSizeChange(newPageSize);
        }}
        labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
        getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
   /*      labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
        labelDisplayedRows={({ from, to, count }) => 
          t('MUI_PAGINATION_RANGE', { 
            from: from + 1, 
            to: Math.min(to, count), 
            count 
          })} */
      />
    </Box>
  );
};

export default ProjectGridView;
