import {
  Box,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import ContactSVG from 'assets/contact-bg.svg';
import { AxiosResponse } from 'axios';
import ButtonCustom from 'components/ButtonCustom';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IErrorResponse } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';
const ContactUs = () => {
  const { user, isAuthenticated } = useContext(UseAuthContext);
  const [email, setEmail] = useState(isAuthenticated && user ? user.email : '');
  const [name, setName] = useState(isAuthenticated && user ? user.name : '');
  const [topic, setTopic] = useState('');
  const [phone, setPhone] = useState(isAuthenticated && user ? user.phone : '');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async (data: any) => {
      const response = await axiosInstance.post<any, AxiosResponse<any>>(
        API_PATHS.CONTACT_US,
        JSON.stringify({
          email: data.email,
          name: data.name,
          desc: data.description,
          phone: data.phone,
          topic: data.topic,
        })
      );
      return response;
    },
    onSuccess() {
      setSuccess(true);
      enqueueSnackbar(t('CONTACT_US_MESSAGE_SUCCESS'), { variant: 'success' });
      setEmail('');
      setTopic('');
      setDescription('');
      setName('');
 
    },
    onError(error: IErrorResponse, variables, context) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
    onSettled(data, error, variables, context) {
      console.log('onSettled', data, error, variables, context);
    },
  });
  const handleClick = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data = {
        email,
        name,
        description,
        topic,
        phone,
      };
      await mutation.mutateAsync(data);
      setisLoading(false);
    } catch (error: any) {
      setisLoading(false);
    }
  };
  return (

    <Grid container spacing={3} direction={{ xs: "column", md: "row-reverse", lg: "row-reverse" }} sx={{ mt: "50px" }}>
      <Grid justifyContent={"center"} textAlign={"center"} alignSelf={"center"} size={{ xs: 12, md: "grow", lg:'grow' }} >
        <Box component='img' mt={{xs: '0', md: '100px', lg: '100px'}}   width={{xs: '30%', md: '80%', lg: '440px' }} src={ContactSVG} />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 7 }}>
        {!success ? <Box component='form' className='tab-content-form' sx={{ width: { md: "80%" } }} >
          <Typography textAlign={"center"} variant='h1' >
            {t('CONTACT_US_HEADER')}
          </Typography>
          <TextField
            margin='normal'
            fullWidth
            variant='standard'
            id='fullname'
            name='fullname'
            autoFocus
            disabled={isAuthenticated}
            label={t('CONTACT_US_NAME')}
            autoComplete='off'
            autoCorrect='off'
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            disabled={isAuthenticated}
            name='email'
            label={t('CONTACT_US_EMAIL')}
            id='email'
            type='email'
            autoComplete='off'
            autoCorrect='off'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            disabled={isAuthenticated}
            name='phone'
            label={t('CONTACT_US_PHONE')}
            id='phone'
            type='phone'
            autoComplete='off'
            autoCorrect='off'
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <TextField
            select
            id='demo-simple-select'
            label={t('CONTACT_US_HELP_TOPIC')}
            variant='outlined'
            fullWidth

            onChange={(e) => setTopic(e.target.value)}
            value={topic}
          >
            <MenuItem value={t('CONTACT_US_HELP_SUPPORT')}>
              {t('CONTACT_US_HELP_SUPPORT')}
            </MenuItem>
            <MenuItem value={t('CONTACT_US_HELP_INSTRUCTION')}>
              {t('CONTACT_US_HELP_INSTRUCTION')}
            </MenuItem>
            <MenuItem value={t('CONTACT_US_HELP_RECOMMENDATION')}>
              {t('CONTACT_US_HELP_RECOMMENDATION')}
            </MenuItem>
          </TextField>
          <TextField
            margin='normal'
            fullWidth
            name='help'
            label={t('CONTACT_US_HELP')}
            id='help'
            autoComplete='off'
            autoCorrect='off'
            multiline
            rows={4}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <ButtonCustom
            color='primary'
            type='submit'
            variant='contained'
            size='medium'
            onClick={(e) => handleClick(e)}
          >
            {t('CONTACT_US_SEND')}
          </ButtonCustom>
        </Box> : (
            <Grid container direction={"column"} spacing={2} sx={{ minHeight: { md: '500px' } }}>
            <Grid>
            <Typography variant='h1' textAlign={"center"} >
            {t('CONTACT_US_HEADER_SUCCESS')}
          </Typography>
            </Grid>
            <Grid>
            <Typography variant='h1' textAlign={"center"}>
              {t('CONTACT_US_HEADER1_SUCCESS')}
            </Typography></Grid>
            </Grid>
           )}

      </Grid>
    </Grid>
  );
};

export default ContactUs;
