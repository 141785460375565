import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Autocomplete,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import ButtonCustom from 'components/ButtonCustom';
import ButtonIcon from 'components/ButtonIcon';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import { StyledTableSortLabel } from 'components/TableSortedLabel';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrganisationTransactionsCSV } from 'services/admin-organisations';
import { adminTopUp, getOrganisationTransactions } from 'services/business-setting-service';
import shortid from 'shortid';
import { USER_ROLES } from 'utils/constants';
import { IAdminTopUp, Order } from 'utils/contracts';
import { package_options } from 'utils/Formatter';
import { calcTotalAccountPoints, getErrorHtmlContent } from 'utils/utils';
import * as Yup from 'yup';
import Transaction from './Transaction';

let DEFAULT_ORDER = 'desc',
  DEFAULT_ORDER_BY = 'createdAt';

const textFieldStyles = {
  '& .MuiInputBase-root': {
    height: '50px', // Custom height
    padding: '20px 0px', // Adjust padding as needed
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(12px, 14px) scale(1)', // Adjust label position
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(12px, -10px) scale(0.75)',
  },
};

const BusinessCredits = ({ organizations, refetch, mode }: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER as Order);
  const [orderBy, setOrderBy] = useState<string>(DEFAULT_ORDER_BY);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { role } = useContext(UseAuthContext);
  const queryClient = useQueryClient();
  
  const { mutate: topUpMutation, isLoading: isTopUpLoading } = useMutation(adminTopUp, {
    onSuccess: () => {
      enqueueSnackbar(t('BUSINESS_ADMIN_TRANSACTION_CREDIT_ADD_SUCCESS'), {
        variant: 'success',
      });
      queryClient.invalidateQueries(['organizations']);
      queryClient.invalidateQueries(['transactions']);
      refetch();
      formik.resetForm();
    },
    onError: (error: any) => {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      console.error('Error during top up:', error);
    },
  });

  const { 
    data: transactionsData,
    refetch: refetchTransactions 
  } = useQuery(
    ['transactions', organizations?.id, page, rowsPerPage, order, orderBy],
    () => getOrganisationTransactions(organizations?.id, {
      page: page + 1,
      pageSize: rowsPerPage,
      sortOrder: order,
      sortBy: orderBy
    }),
    {
      enabled: mode !== 'create' && !!organizations?.id,
      refetchOnWindowFocus: true,
      onError: (error: any) => {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      }
    }
  );

  const totalCredits = useMemo(() => {
    if (mode === 'create') return 0;
    return calcTotalAccountPoints(organizations);
  }, [organizations?.transactions, mode]);

  const formik = useFormik({
    initialValues: {
      packageType: '',
      credits: '',
      description: '',
      sum: ''
    },
    validationSchema: Yup.object({
      packageType: Yup.string().required(t('FIELD_REQUIRED')),
      credits: Yup.number().when('packageType', {
        is: 'CUSTOM',
        then: () => Yup.number().required(t('FIELD_REQUIRED'))
      }),
      description: Yup.string().when('packageType', {
        is: 'CUSTOM',
        then: () => Yup.string().required(t('FIELD_REQUIRED'))
      }),
    }),
    onSubmit: async (values) => {
      if (mode === 'create') {
        enqueueSnackbar(t('BUSINESS_ADMIN_CREDIT_CREATE_FIRST'), {
          variant: 'warning',
        });
        return;
      }

      const topUpData: IAdminTopUp = {
        ProdQuantity: 1,
        OrganizationId: organizations.id,
        ProductDesc: values.description,
        TransactionId: shortid.generate(),
        ProductID: values.packageType,
      };

      if (values.packageType === 'CUSTOM') {
        topUpData.suminfull = values.sum;
        topUpData.TotalPoints = values.credits;
      }
      topUpMutation(topUpData);
      refetchTransactions();
    },
  });


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleDownloadTransactions = async () => {
    try {
      const response = await getOrganisationTransactionsCSV(organizations.id);
      const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `transactions_${organizations.company_name}_${moment().format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
    }
  };

  return (
    <Box>
      <Box className='business_credits_header'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <TypographyLocale variant='h2' fontWeight='bold'>
            <span>{totalCredits} </span>
            <span>{t('EDIT_BUSINESS_HEADER_BALANCE')}</span>
          </TypographyLocale>

        </Box>
        {role === USER_ROLES.ADMIN && mode !== 'create' && (
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Autocomplete
                    clearIcon={<></>}
                    options={package_options}
                    getOptionLabel={(option: any) => option.value}
                    onChange={(_, value: any) => {
                      formik.setFieldValue('packageType', value?.value);
                      if (value?.value !== 'CUSTOM') {
                        formik.setFieldValue('credits', '');
                        formik.setFieldValue('description', '');
                      }
                    }}
                    size='small'
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="packageType"
                        label={t("ADMIN_ADD_BUSINESS_CREDIT_PACKAGE_SELECT")}
                        sx={{ ...textFieldStyles, width: "220px" }}
                        required
                      />
                    )}
                  />
                  <TextField
                    name="credits"
                    label={t("ADMIN_ADD_BUSINESS_CREDIT_POINTS")}
                    value={formik.values.credits}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    
                    disabled={formik.values.packageType !== 'CUSTOM'}
                    sx={textFieldStyles}
                    required={formik.values.packageType === 'CUSTOM'}
                  />
                
                  <TextField
                    name="description"
                    label={t("ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION")}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                 
                    disabled={formik.values.packageType !== 'CUSTOM'}
                    sx={textFieldStyles}
                    required={formik.values.packageType === 'CUSTOM'}
                  />
               
                  <ButtonCustom
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={isTopUpLoading}
                    startIcon={isTopUpLoading ? <CircularProgress size={20} color='inherit'/> : null}
                  >
                    {t("SAVE_BUTTON")}
                  </ButtonCustom>
              </Box>
              <Box textAlign={"right"} alignSelf={"flex-end"}>
                {role === USER_ROLES.ADMIN && (
                  <ButtonIcon
                    onClick={handleDownloadTransactions}
                  >
                    <FileDownloadOutlinedIcon fontSize='large' />
                  </ButtonIcon>
                )}
              </Box>
            </Box>
          </form>)}

        {mode !== 'create' && (
          <TableContainer>
            <Table size="small">
              <TableHead >
                <CustomTableFontCell                     sx={{textAlign: 'center'}}
 >
                  <StyledTableSortLabel
                    active={orderBy === "type"}
                    direction={orderBy === "type" ? order : "asc"}
                    onClick={() => createSortHandler("type")}
                  >
                    {t('BUSINESS_SETTING_TRANSACTION_STATUS')}
                    {orderBy === "type" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </StyledTableSortLabel>
                </CustomTableFontCell>

                <CustomTableFontCell >
                  <StyledTableSortLabel
                  
                    active={orderBy === "createdAt"}
                    direction={orderBy === "createdAt" ? order : "asc"}
                    onClick={() => createSortHandler("createdAt")}
>
                    {t('BUSINESS_SETTING_TRANSACTION_DATE')}
                    {orderBy === "createdAt" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </StyledTableSortLabel>
                </CustomTableFontCell>
                <CustomTableFontCell >
                  <StyledTableSortLabel
                    active={orderBy === "user"}
                    direction={orderBy === "user" ? order : "asc"}
                    onClick={() => createSortHandler("user")}
                  >
                    {t('BUSINESS_SETTING_TRANSACTION_BY')}
                    {orderBy === "user" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </StyledTableSortLabel>
                </CustomTableFontCell >
                <CustomTableFontCell >
                  <StyledTableSortLabel
                    active={orderBy === "project"}
                    direction={orderBy === "project" ? order : "asc"}
                    onClick={() => createSortHandler("project")}
                  >
                    {t('EDIT_BUSINESS_TABLE_FOR')}
                    {orderBy === "project" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </StyledTableSortLabel>
                </CustomTableFontCell>
                <CustomTableFontCell  >
                  <StyledTableSortLabel
                    active={orderBy === "points"}
                    direction={orderBy === "points" ? order : "asc"}
                    onClick={() => createSortHandler("points")}
                  >
                    {t('BUSINESS_SETTING_TRANSACTION_CREDITS')}
                    {orderBy === "points" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </StyledTableSortLabel>
                </CustomTableFontCell>
              </TableHead>
              <TableBody>
                {transactionsData?.data?.length > 0 &&
                
                  transactionsData.data.map((transaction) => {
                      return <Transaction
                        key={transaction.id}
                        transaction={transaction}
                      />
                    })}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ width: "100%" }}
              rowsPerPageOptions={[20]}
              component='div'
              count={transactionsData?.paging?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
              labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
              getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
            />
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

export default BusinessCredits;
