import { MoreHoriz } from '@mui/icons-material'; // Adjust import based on icon library
import { Card, CardContent, CardMedia, styled, Typography } from '@mui/material';
import ProjectPlaceHolder from 'assets/Project_PlaceHolder.svg';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { IProjectListResponse } from 'utils/contracts';
import { formatClimat, formatType } from 'utils/Formatter';
import { ellipsis } from 'utils/utils';
import Restrict from './Restrict';

const ProjectTileNew = ({ ...props }: any) => {
  const {
    bg_color,
    name,
    climate,
    createdBy,
    image_url,
    type,
    updatedAt,
    revisions,
    id,
    handleDeleteProject
  } = props.data as IProjectListResponse;
  const { language } = useContext(UseLanguageContext);
  const { businessId } = useParams();
  const { role } = useContext(UseAuthContext);
  const isAdmin = role && role.toLowerCase() === USER_ROLES.ADMIN;
  const { refetch } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formattedClimate = t(formatClimat(climate));
  const formattedType = t(formatType(type));
  const { enqueueSnackbar } = useSnackbar();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredId, setHoveredId] = useState<string | null>(null);



  const editProjecthandler = async (e: any, projectId: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (businessId) {
      navigate(`/edit-project/${projectId}/${businessId}`);
    } else {
      navigate(`/edit-project/${projectId}`);
    }
  };



  const clickHandler = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAdmin && businessId) {
      navigate(`/admin/business/${businessId}/project/${id}/board`);
    } else {
      navigate(`/project/${id}/board`);
    }
  };

  const toggleMenu = (e: any) => {
    e.preventDefault()
    setMenuOpen(!menuOpen);
  };

  const handleMouseEnter = (e: React.MouseEvent, projectId: string) => {
    if (hoveredId !== projectId) {
      setHoveredId(projectId);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    setHoveredId(null);
    setMenuOpen(false);
  };

  const StyledCard = styled(Card)({
    width: 164,
    boxShadow: 'none',
    border: "lightgrey 1px solid",
    position: 'relative',
    '& .MuiCardActionArea-root': {
      boxShadow: 'none',
    },
    '& .overlay-content': {
      pointerEvents: 'none',
      '& .overlay-menu': {
        pointerEvents: 'auto'
      }
    }
  });

  return (
    < >
      <div
        key={id}
         style={{ width: 219, height: 307, border: "lightgrey 1px solid", borderRadius: "3px" }}
       
      >
        <div
          onMouseEnter={(e) => handleMouseEnter(e, id)}
          onMouseLeave={handleMouseLeave}

              >
          <div className="card-media-overlay">
            <div className="overlay-date">
              <div>
                {moment.utc(updatedAt).local().format('DD/MM/YY')}
              </div>
              <div>
                {moment.utc(updatedAt).local().format('HH:mm')}
              </div>
            </div>
            <CardMedia
              component="img"
              height="217"
              src={image_url || ProjectPlaceHolder}
              alt="project image"
            />
            <div className="overlay-content" style={{ opacity: hoveredId == id ? 1 : 0 }}>
              <div className="overlay-top">
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', fontWeight: "bolder" }}>
                  <div>
                    {moment.utc(updatedAt).local().format('DD/MM/YY')}
                  </div>
                  <div>
                    {moment.utc(updatedAt).local().format('HH:mm')}
                  </div>
                </div>
               
                  <div className="overlay-menu" onClick={toggleMenu} onMouseLeave={() => setMenuOpen(false)} >
                    <MoreHoriz className="menu-icon" />
                    {menuOpen && (
                      <div className={`menu-${language}`}>
                        <div className="menu-item" onClick={(e) => editProjecthandler(e, id)}>
                          {t('PROJECT_EDIT_MENU')}
                        </div>
                        <div className="menu-item" onClick={(e) => clickHandler(e)}>
                          {t('PROJECT_MENU_VIEW')}
                        </div>
                        <Restrict forbidden={['viewer']}>
                        <div className="menu-item" onClick={(e) => handleDeleteProject(e, id)}>
                          {t('PROJECT_MENU_DELETE')}
                        </div>
                        </Restrict>

                      </div>
                    )}
                  </div>
              </div>
              <div className="overlay-center" onClick={(e) => clickHandler(e)}>
                <div className='quantity'>{revisions.length}</div>
                <div className='details__description'>{t('PROJECT_LIST_TABLE_ALTERNATIVES')}</div>
              </div>
            </div>
          </div>
          <CardContent sx={{ p: 0 }}>
            <div className='item__description'>
              <Typography className='item__title' title={name}>
                {ellipsis(name, 18)}
              </Typography>
              <div className='item__text'>
                <Typography variant="body2" className='item__project_type' title={formattedType}>
                  {ellipsis(formattedType, 16)}
                </Typography>
                <Typography variant="body2" className='item__project_type' title={formattedClimate}>
                  {ellipsis(formattedClimate, 15)}
                </Typography>
              </div>
            </div>
          </CardContent>
        </div>
      </div>
      {/*
      <div className='item__info'>
        <Box
          className='imgbx'
          sx={image_url ? {} : { backgroundColor: '#AECEFA' }}
        >
          {image_url ? (
            <img src={image_url} alt='' />
          ) : (
            // <Box className='imgbx__placeholder'>
            <img
              src={ProjectPlaceHolder}
              alt=''
              style={{
                padding: '3rem',
                objectFit: 'none',
              }}
            />
            // </Box>
          )}
        </Box>
        <div className='details'>
          <div className='details__info'>
            <Restrict forbidden={['viewer']}>
              <Box className='details__btn'>
                <div >
                <span></span>
                <span></span>
                <span></span>
                </div>
              </Box>
            </Restrict>
            <div className='details__text'>
              <div className='quantity'>{total_revisions}</div>
              <div className='details__description'>Alternatives</div>
            </div>
            <div className='details__list'>
              <ul >
                <li onClick={(e) => editProjecthandler(e, id)}>
                  {t('PROJECT_EDIT_MENU')}
                </li>
               <li style={{}}>
                  {t('PROJECT_CHANGE_COLOR')}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0px',
                      left: '-90px',
                      zIndex: '6',
                    }}
                  >
                    <TwitterPicker
                      color={bg_color}
                      onChangeComplete={(color: any) => {
                        console.log(color);
                      }}
                    />
                  </Box>
                </li>
                <li onClick={(e: any) => deleteProjecthandler(e, id)}>
                  {t('PROJECT_DELETE')}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='data_time'>
          <span className='time'>
            {moment.utc(updatedAt).local().format('HH:mm')}
          </span>
          <span className='data'>
            {moment.utc(updatedAt).local().format('DD/MM/YY')}
          </span>
        </div>
      </div>
      <div className='item__description'>
        <div className='item__title' title={name}>
          {ellipsis(name, 20)}
        </div>
        <div className='item__text'>
          <span className='item__project_type' title={formattedType}>
            {ellipsis(formattedType, 15)}
          </span>
          <span className='item__project_type' title={formattedClimate}>
            {ellipsis(formattedClimate, 10)}
          </span>
        </div>
      </div>
      */}
    </>
  );
};

export default ProjectTileNew;
