import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const InviteSignUp = () => {
  const { invitationDetails } = useParams();
  const inviteDetails = invitationDetails?.split('|');
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/signUpByInvite', {
      state: {
        email: inviteDetails?.[0],
        organisationID: inviteDetails?.[1],
        language: inviteDetails?.[2],
      },
    });
  }, []);

  return <div>InviteSignUp</div>;
};

export default InviteSignUp;
