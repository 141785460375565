import React from 'react';

const GraphIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="M37.5 18C38.3284 18 39 17.3284 39 16.5C39 15.6716 38.3284 15 37.5 15C36.6716 15 36 15.6716 36 16.5C36 17.3284 36.6716 18 37.5 18ZM37.5 20C39.433 20 41 18.433 41 16.5C41 14.567 39.433 13 37.5 13C35.567 13 34 14.567 34 16.5C34 18.433 35.567 20 37.5 20Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M28.5 30C29.3284 30 30 29.3284 30 28.5C30 27.6716 29.3284 27 28.5 27C27.6716 27 27 27.6716 27 28.5C27 29.3284 27.6716 30 28.5 30ZM28.5 32C30.433 32 32 30.433 32 28.5C32 26.567 30.433 25 28.5 25C26.567 25 25 26.567 25 28.5C25 30.433 26.567 32 28.5 32Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M21.5 22C22.3284 22 23 21.3284 23 20.5C23 19.6716 22.3284 19 21.5 19C20.6716 19 20 19.6716 20 20.5C20 21.3284 20.6716 22 21.5 22ZM21.5 24C23.433 24 25 22.433 25 20.5C25 18.567 23.433 17 21.5 17C19.567 17 18 18.567 18 20.5C18 22.433 19.567 24 21.5 24Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M12.5 34C13.3284 34 14 33.3284 14 32.5C14 31.6716 13.3284 31 12.5 31C11.6716 31 11 31.6716 11 32.5C11 33.3284 11.6716 34 12.5 34ZM12.5 36C14.433 36 16 34.433 16 32.5C16 30.567 14.433 29 12.5 29C10.567 29 9 30.567 9 32.5C9 34.433 10.567 36 12.5 36Z" fill="currentColor"/>
		<rect x="18.8958" y="21" width="3" height="10" transform="rotate(36.127 18.8958 21)" fill="currentColor"/>
		<rect x="34.8958" y="18" width="3" height="10" transform="rotate(36.127 34.8958 18)" fill="currentColor"/>
		<rect x="22" y="22.8581" width="3" height="5.62183" transform="rotate(-38.2702 22 22.8581)" fill="currentColor"/>
	</svg>
);

export default GraphIcon;