import { Container, Grid2 } from '@mui/material';
import SplashScreen from 'pages/SplashScreen';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const MainContent = () => (
  <Suspense fallback={<SplashScreen />}>
    <Outlet />
  </Suspense>
);

export const Layout = () => {
  return (
    <Container 
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px !important',
        overflow: 'hidden'
      }}
    >
      <Grid2 component='header' sx={{ width: '100%' }}>
        <Header />
      </Grid2>
      <Grid2 
        className="body-container" 
        sx={{ 
          flex: 1,
          width: '100%',
          padding: '20px 0'
        }}
      >
        <MainContent />
      </Grid2>
      <Grid2 component='footer' sx={{ marginTop: 'auto', width: '100%' }}>
        <Footer />
      </Grid2>
    </Container>
  );
};
