import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { createContext, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { GetUserOrganization } from 'services/auth-service';
import { updatePrivateAreaLanguage } from 'services/private-area-services';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS, USER_ROLES } from 'utils/constants';
import {
  Auth,
  IOrganisationResponse,
  IUserLoginRequest,
  IUserLoginResponse,
  User,
} from 'utils/contracts';
import { RemoveAllLoggedInUsersData } from 'utils/utils';
import LanguageContext from './LanguageContext';

interface IAuthContext {
  isAuthenticated?: boolean;
  user?: User;
  role?: string;
  auth?: Auth;
  organisationId?: string;
  setOrganisationId: (orgId: string) => void;
  organizations?: IOrganisationResponse;
  login: (data: IUserLoginRequest) => Promise<void>;
  logout: (path?: any) => void;
  getOrganisation: (orgId: any) => Promise<void>;
  setOrganisation: (org: any) => void;
  adminLogin: (data: any) => Promise<void>;
  adminLogout: (path?: any) => void;
  loginAfterInvitationProcess: any;
  updateUser: (userData: User) => void;
  setRole: (role: string) => void;

}

interface IJWTDecode {
  exp: number;
  iat: number;
  id: string;
  name: string;
  authorization: string;
  organizationId: string;
  email: string;
  phone: string;
  address: string;
  language: string;
}

const UseAuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: any) => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const {language, setLanguage} = useContext(LanguageContext)
  const [isAuthenticated, setIsAuthenticated] = useState(
    () =>
      localStorage.getItem('token') !== null &&
      localStorage.getItem('user') !== null &&
      localStorage.getItem('auth') !== null
  );
  const [role, setRole] = useState(() => {
    const role = localStorage.getItem('role') ?? null;
    return role;
  });

  const [organisationId, setOrganisationId] = useState(() => {
    const Id = localStorage.getItem('organizationId') ?? null;
    return Id;
  });
  const [auth, setauth] = useState(() => {
    const auth = localStorage.getItem('auth');
    return auth ? JSON.parse(auth) : null;
  });
  const [user, setuser] = useState(() => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  });
  const [organizations, setOrganizations] = useState(() => {
    const organizations = localStorage.getItem('organizations');
    return organizations ? JSON.parse(organizations) : null;
  });
  const navigate = useNavigate();

  const login = async (data: IUserLoginRequest) => {
    await loginMutation.mutateAsync(data);
  };

  const adminLogin = async (data: any) => {
    await adminLoginMutation.mutateAsync(data);
  };

  const getOrganisation = async (orgId: any) => {
    await organisationMutation.mutateAsync(orgId);
  };

  const setOrganisation = async (org: any) => {
    if (org) {
      setOrganizations(org);
      localStorage.setItem('organizations', JSON.stringify(org));

    }
  };

  const adminLoginMutation = useMutation({
    mutationFn: async (data: any) => {
      const loginResponse = await axiosInstance.post<any, AxiosResponse<any>>(
        API_PATHS.ADMIN_LOGIN,
        JSON.stringify({
          password: data.password,
        })
      );
      return loginResponse;
    },
    async onSuccess(data) {
      const responseData = data.data;
      const decoded: IJWTDecode = jwt_decode<any>(responseData.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        logout();
      }
      const auth = {
        exp: decoded.exp,
        id: USER_ROLES.ADMIN,
        token: responseData.token,
      };
      const calculateduser = {
        role: USER_ROLES.ADMIN,
      };

      localStorage.setItem('auth', JSON.stringify(auth));

      localStorage.setItem('user', JSON.stringify(calculateduser));
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('role', USER_ROLES.ADMIN);
      setuser(calculateduser);
      setIsAuthenticated(responseData?.token !== null);
      setauth(auth);
      setRole(USER_ROLES.ADMIN);
      navigate('/admin/dashboard', { replace: true });
    },
    onError(error, variables, context) {
      console.log('onError', error, variables, context);
    },
    onSettled(data, error, variables, context) {
      console.log('onSettled', data, error, variables, context);
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (data: IUserLoginRequest) => {
      const loginResponse = await axiosInstance.post<
        any,
        AxiosResponse<IUserLoginResponse>
      >(
        API_PATHS.NORMAL_USER_LOGIN,
        JSON.stringify({
          email: data.email,
          password: data.password,
        })
      );
      return loginResponse;
    },
    async onSuccess(data) {
      const responseData = data.data;
      const decoded: IJWTDecode = jwt_decode<any>(responseData.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        logout();
      }
      const auth = {
        exp: decoded.exp,
        id: decoded.id,
        token: responseData.token,
      };

      const calculateduser = {
        id: decoded.id,
        name: decoded.name,
        organizationId: decoded.organizationId,
        role: decoded.authorization,
        email: decoded.email,
        phone: decoded.phone,
        address: decoded.address,
      };
      localStorage.setItem('auth', JSON.stringify(auth));
      localStorage.setItem('user', JSON.stringify(calculateduser));
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('role', decoded.authorization);
      localStorage.setItem('organizationId', decoded.organizationId);
      setuser(calculateduser)
      setIsAuthenticated(responseData?.token !== null);
      setauth(auth);
      setRole(decoded.authorization);
      setOrganisationId(decoded.organizationId);
      if(language !== decoded.language){
        await updatePrivateAreaLanguage({language: language})
        setLanguage(language)
      }else{
        setLanguage(language)
      }
      await organisationMutation.mutateAsync(decoded.organizationId);
      navigate('/', { replace: true });
    },
    onError(error, variables, context) {
      console.log('onError', error, variables, context);
    },
    onSettled(data, error, variables, context) {
      console.log('onSettled', data, error, variables, context);
    },
  });

  const loginAfterInvitationProcess = async (jwtData: any) => {
    const responseData = jwtData;
    const decoded: IJWTDecode = jwt_decode<any>(responseData.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      logout();
    }
    const auth = {
      exp: decoded.exp,
      id: decoded.id,
      token: responseData.token,
    };
    const calculateduser = {
      id: decoded.id,
      name: decoded.name,
      organizationId: decoded.organizationId,
      role: decoded.authorization,
      email: decoded.email,
      phone: decoded.phone,
      address: decoded.address,
    };
    localStorage.setItem('auth', JSON.stringify(auth));
    localStorage.setItem('user', JSON.stringify(calculateduser));
    localStorage.setItem('token', responseData.token);
    localStorage.setItem('role', decoded.authorization);
    localStorage.setItem('organizationId', decoded.organizationId);
    setuser(calculateduser);
    setIsAuthenticated(responseData?.token !== null);
    setauth(auth);
    setRole(decoded.authorization);
    setOrganisationId(decoded.organizationId);
    await organisationMutation.mutateAsync(decoded.organizationId);
    navigate('/', { replace: true });
  };

  const organisationMutation = useMutation({
    mutationFn: async (orgId: any) => {
      return await GetUserOrganization(orgId);

    },
    onSuccess(data) {
      const { transactions, ...rest } = data;
      setOrganizations(rest);
      localStorage.setItem('organizations', JSON.stringify(rest));
    },
    onError(error, variables, context) {
      console.log('onError', error, variables, context);
    },
  });

  const adminLogout = (path: any = '') => {
    RemoveAllLoggedInUsersData();
    const redirectPath = path ? `?redirect=${path}` : '';
    setuser(null);
    setIsAuthenticated(false);
    setauth(null);
    setRole(null);
    navigate(`admin/login${redirectPath}`, { replace: true });
  };

  const logout = (path: any = '') => {
    RemoveAllLoggedInUsersData();
    const redirectPath = path ? `?redirect=${path}` : '';
    setOrganizations(null);
    setOrganisationId(null);
    setuser(null);
    setIsAuthenticated(false);
    setauth(null);
    setRole(null);
    navigate(`/login${redirectPath}`, { replace: true });
  };

  const updateUser = (userData: User) => {
    setuser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };
/*
  useEffect(() => {
    const events = ["mouseover", "keydown", "click"]
    const updateLastActivity = () => {
      setLastActivity(Date.now())
    }

    events.forEach((event) =>
      window.addEventListener(event, updateLastActivity)
    )

    return () => {
      events.forEach((event) =>window.removeEventListener(event, updateLastActivity))
    };
  }, [])

   useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated && Date.now() - lastActivity > 60 * 10 * 1000) {
        logout();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [lastActivity]) */

  return (
    <UseAuthContext.Provider
      value={
        {
          login,
          logout,
          isAuthenticated,
          user,
          organizations,
          auth,
          role,
          setRole,
          organisationId,
          setOrganisationId,
          getOrganisation,
          setOrganisation,
          adminLogin,
          adminLogout,
          loginAfterInvitationProcess,
          updateUser
        } as IAuthContext
      }
      key={'LOGINCONTEXT'}
    >
      {children}
    </UseAuthContext.Provider>
  );
};

export default UseAuthContext;
