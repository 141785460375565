import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import strategyClient from 'utils/storage/strategyClient';
import { clientConfig } from 'utils/utils';
import { IProjectAddRequest } from './../utils/contracts';

export const getProjectLists = async (params: { 
  page?: number;
  pageSize?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: string;
  search?: string;
}) => {
  const res = await axiosInstance.post(
    API_PATHS.PROJECTS_LIST,
    params
  );
  return res.data;
};

export const saveProject = async (project: IProjectAddRequest) => {
    let projectId = project.id
    let organizationId = project.organizationId

    if (project.preview) {
        const uploaderService = strategyClient.getStrategy(
            'image',
            projectId,
            5000
        )
        const path =
            clientConfig.cloudinaryFolder +
            '/organizations/' +
            organizationId +
            '/projects/' +
            projectId +
            '/cover'

        const file = await uploaderService.uploadObject({
            publicId: path,
            id: projectId,
            type: 'image',
            file: project.preview,
            destination: path,
            size: { height: 312, width: 312 },
        } as any)
        project.image_url = file.webContentLink
        project.publicId = file.publicId
    }

    let data = { ...project }
    delete data.preview

    const res = await axiosInstance.post(API_PATHS.PROJECTS_Add, data)
    return res
}

export const deleteProject = async (projectId: string) => {
    const res = await axiosInstance.delete(
        API_PATHS.PROJECTS_Delete + projectId
    )
    return res
}

export const getProjectById = async (projectId: string) => {
    const data = await axiosInstance.get(
        API_PATHS.PROJECTS_Add + '/' + projectId
    )
    return data.data
}

export const getProjectByIdAsAdmin = async (
    projectId: string,
    organizationId: string
) => {
    const data = await axiosInstance.get(
        API_PATHS.PROJECTS_Add + '/' + projectId,
        {
            headers: {
                'x-organization-id': organizationId,
            },
        }
    );
    return data.data
}

export const updateProject = async (project: IProjectAddRequest) => {
    let projectId = project.id
    let organizationId = project.organizationId

    if (project.preview) {
        const uploaderService = strategyClient.getStrategy(
            'image',
            projectId,
            5000
        )
        const path =
            clientConfig.cloudinaryFolder +
            '/organizations/' +
            organizationId +
            '/projects/' +
            projectId +
            '/cover'

        const file = await uploaderService.uploadObject({
            publicId: path,
            id: projectId,
            type: 'image',
            file: project.preview,
            destination: path,
            size: { height: 312, width: 312 },
        } as any)
        project.image_url = file.webContentLink
        project.publicId = file.publicId
    }

    let data = { ...project }
    delete data.preview

    const res = await axiosInstance.put(
        `${API_PATHS.PROJECTS_Add}/${data.id}`,
        data
    )
    return res
}

export const downloadAndZipFiles = async (
    fileName: string,
    fileURIs: any[]
) => {
    const zip = new JSZip()
   const fetchFilePromises = fileURIs.map(async (fileURI) => {
       const response = await fetch(fileURI.url)
       const blob = await response.blob()
       const fileName = fileURI.name
       zip.file(fileName, blob)
   })
    await Promise.all(fetchFilePromises)
    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, fileName)
}

export const getAllRevisionFiles = async (
    OrgId: string,
    projectId: string,
    id: string
) => {
    const response = await axiosInstance.get(
        `/pipe/${OrgId}/${projectId}/${id}/download/all`
    )
    const { fileName, fileURIs } = response.data
    await downloadAndZipFiles(fileName, fileURIs)
}

export const getProjectWithTransactions = async (
    projectId: string,
    organizationId: string
) => {
    const response = await axiosInstance.get(
        `/projects/${projectId}/transactions`,
        {
            headers: {
                'x-organization-id': organizationId,
            },
        }
    )
    return response.data
}