import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';

interface OrganisationListParams {
  page?: number;
  pageSize?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: string;
  search?: string | null;
}

export const getOrganisationLists = async ({
  page = 1,
  pageSize = 20,
  sortOrder = 'asc',
  sortBy = 'company_name',
  search = null
}: OrganisationListParams = {}) => {
  const response = await axiosInstance.post(API_PATHS.ADMIN_GET_ORGANISATIONS_LIST, {
    page,
    pageSize,
    sortOrder,
    sortBy,
    search
  });
  return response.data;
};

export const getOrganisationListsCSV = async () => {
  const response = await axiosInstance.get(API_PATHS.ADMIN_GET_ORGANISATIONS_CSV);
  return response.data;
};

export const getOrganisationTransactionsCSV = async (organizationId: string, query?: any) => {
  const response = await axiosInstance.get(
    `${API_PATHS.GET_ORGANISATION_TRANSACTIONS}/${organizationId}/transactions`,
    query
  );
  return response.data;
};