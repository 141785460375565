import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export default function HReportBadge(props: any) {
  let { pass } = props;

  if (pass !== undefined) {
    return pass === true ? (
      <CheckIcon
        htmlColor='#4fb947'
        fontSize='small'
        style={{ padding: '0' }}
      />
    ) : (
      <ClearIcon
        htmlColor='#b92126'
        fontSize='small'
        style={{ padding: '0' }}
      />
    );
  } else {
    return null;
  }
}
