import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IUpdateLanguage, IUpdatePassword, IUpdatePrivateArea } from './../utils/contracts';

export const updatePrivateArea = async (updateDetails: IUpdatePrivateArea) => {
  const {data} = await axiosInstance.put(API_PATHS.PRIVATE_AREA_UPDATE, updateDetails);
  return data;
};

export const updatePrivateAreaLanguage = async (language: IUpdateLanguage) => {
  const {data} = await axiosInstance.put(API_PATHS.PRIVATE_AREA_LANGUAGE_UPDATE, language);
  return data;
};

export const updatePrivateAreaPassword = async (
  updatePassword: IUpdatePassword
) => {
  return await axiosInstance.put(
    API_PATHS.PRIVATE_AREA_PASSWORD_UPDATE,
    updatePassword
  );
};

export const getPrivateAreaDetails = async () => {
  return await axiosInstance.get(API_PATHS.PRIVATE_AREA_GET);
};
