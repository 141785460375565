import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonCustom from 'components/ButtonCustom';
import Spinner from 'components/Spinner';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPrivateAreaDetails } from 'services/private-area-services';
import { getErrorHtmlContent } from 'utils/utils';

const UserPersonalInfoEdit = ({ updatePrivateDetails }: any) => {
  const { t } = useTranslation();
  const { updateUser } = useContext(UseAuthContext);
  const [isLoading, setisLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading: dataLoading,
    status,
    refetch: refetchPersonalDetails,
  } = useQuery('getPrivateAreaDetails', getPrivateAreaDetails);

  const [name, setName] = useState(data?.data?.name);
  const [phone, setPhone] = useState(data?.data?.phone);
  const [role, setRole] = useState(data?.data?.role);
  useEffect(() => {
    setName(data?.data?.name);
    setPhone(data?.data?.phone);
    setRole(data?.data?.role);
  }, [data]);

  const handleClick = async (e: any) => {
    try {
      e.preventDefault();
      setisLoading(true);
      await updatePrivateDetails({
        name,
        phone,
        role,
      });
      await refetchPersonalDetails();
      enqueueSnackbar(t('PRIVATE_AREA_PERSONAL_INFO_UPDATE_SUCCESS'), {
        variant: 'success',
      });
      setisLoading(false);
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  if (dataLoading) {
    return <Spinner />;
  }
  return (
    <Box component='form' sx={{ mx: '2rem', display:"flex", gap:"30px", width:{xs:"100%", md:"480px"} }} className='tab-content-form'>
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='fullname'
        name='fullname'
        autoFocus
        label={t('USER_EDIT_PI_NAME')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('USER_EDIT_PI_NAME_HELPER_TEXT')}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <TextField
        margin='normal'
        variant='standard'
        fullWidth
        name='mobile'
        label={t('USER_EDIT_PI_MOBILE')}
        id='mobile'
        type='text'
        autoComplete='off'
        autoCorrect='off'
        onChange={(e) => setPhone(e.target.value)}
        value={phone}
        helperText={t('USER_EDIT_PI_MOBILE_HELPER_TEXT')}
      />
      <TextField
        margin='normal'
        variant='standard'
        fullWidth
        name='role'
        label={t('USER_EDIT_PI_ROLE')}
        id='role'
        type='text'
        autoComplete='off'
        autoCorrect='off'
        value={role}
        onChange={(e) => setRole(e.target.value)}
        helperText={t('USER_EDIT_PI_ROLE_HELPER_TEXT')}
      />
        <ButtonCustom
          color='primary'
          type='submit'
          variant='contained'

          onClick={(e: any) => handleClick(e)}
        >
          {t('SAVE_BUTTON')}
        </ButtonCustom>

    </Box>
  );
};

export default UserPersonalInfoEdit;
