export const API_PATHS = {
    NORMAL_USER_LOGIN: '/auth',
    GET_VERSION: '/version',
    NORMAL_USER_FORGOT: '/forgot',
    ORG_REGISTRATION: 'organizations/register',
    PROJECTS_LIST: 'projects/list',
    PROJECTS_Add: 'projects',
    PROJECTS_Delete: 'projects/',
    GET_ORGANISATION: 'organizations/',
    GET_ORGANISATION_TRANSACTIONS: 'admin/organizations',
    UPDATE_BUSINESS_SETTINGS: 'organizations/',
    INVITE_MEMBER_BUSINESS_SETTINGS: 'invites',
    GET_PRICINGS: 'pricing/plans',
    ADMIN_LOGIN: '/admin/auth',
    CONTACT_US: '/contactus',
    PRIVATE_AREA_GET: '/user',
    PRIVATE_AREA_UPDATE: '/users',
    PRIVATE_AREA_LANGUAGE_UPDATE: '/users/language',
    PRIVATE_AREA_PASSWORD_UPDATE: '/user/pass',
    REVISIONS: '/revisions',
    ADMIN_ADD_BUSINESS: 'admin/organizations',
    ADMIN_TOP_UP: 'admin/topup',
    ADMIN_GET_ORGANISATIONS_CSV: 'admin/organizations/csv',
    ADMIN_GET_ORGANISATIONS_LIST: 'admin/organizations/list',
    ADMIN_DELETE_ORGANIZATION: 'admin/organizations',
    DELETE_REVISION: 'revisions',
    TICKETS: '/tickets',
    UPDATE_USER_PERMISSION: '/user/permission',
    USER_RESET_PASSWORD: '/newpass',
    FEATURES: '/features',
}

export const USER_ROLES = {
  VIEWER: 'viewer',
  MEMBER: 'member',
  MANAGER: 'manager',
  ADMIN: 'admin',
};
