import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const LoginGate = () => {
  const { loginDetails } = useParams();
  const loginDetailsArray = loginDetails?.split('|');
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login', {
      state: {
        email: loginDetailsArray?.[0],
        language: loginDetailsArray?.[1],
      },
    });
  }, []);

  return <div>LoginGate</div>;
};

export default LoginGate;
