import { FacebookRounded } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Grid, Link } from '@mui/material';
import HeaderMenu from './HeaderMenu';
const Footer = () => {
  return (
    <Box
      className='footer'
      sx={{
        py: "30px",
      }}
    >
      <Grid container justifyContent='space-between' alignItems='center' flexWrap={"nowrap"}>
        <Box className='menu-items menu-footer' sx={{alignItems:"center"}}>
          <HeaderMenu isForHeader={false} />
        </Box>
        <div  >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: "nowrap"
            }}
          >
            <Link
              style={{ padding: "0" }}
              href='https://www.facebook.com/DesignBuilderIsrael/'
              target='_blank'
              rel='noreferrer'
              display={"flex"}
              sx={{
                color: "#fff !important",
                bgcolor: "#000",
                borderRadius: "50%",
                padding: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28px",
                height: "28px",
                '& svg': {
                  color: "#fff !important",
                },
                '&:hover': {
                  bgcolor: "#333",
                  '& svg': {
                    color: "#fff !important",
                  }
                }
              }}
            >
              <FacebookRounded sx={{ fontSize: "17px" }} />
            </Link>
            <Link
              display={"flex"}
              href='https://www.youtube.com/playlist?list=PLu8h-kasuylHzBBmA_3pCtdMYDnmuyF-A'
              target='_blank'
              rel='noreferrer'
              sx={{
                color: "#fff !important",
                bgcolor: "#000",
                borderRadius: "50%",
                padding: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28px",
                height: "28px",
                '& svg': {
                  color: "#fff !important",
                },
                '&:hover': {
                  bgcolor: "#333",
                  '& svg': {
                    color: "#fff !important",
                  }
                }
              }}
            >
              <YouTubeIcon sx={{ fontSize: "16px" }} />
            </Link>
            <Link
              display={"flex"}
              sx={{
                color: "#fff !important",
                bgcolor: "#000",
                borderRadius: "50%",
                padding: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28px",
                height: "28px",
                '& svg': {
                  color: "#fff !important",
                },
                '&:hover': {
                  bgcolor: "#333",
                  '& svg': {
                    color: "#fff !important",
                  }
                }
              }}
              href='https://www.linkedin.com/company/designbuilder-israel/about/?viewAsMember=true'
              target='_blank'
              rel='noreferrer'
            >
              <LinkedInIcon sx={{ fontSize: "16px" }} />
            </Link>
          </Box>
        </div>
      </Grid>
    </Box>
  );
};

export default Footer;
