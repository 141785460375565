import React from 'react';

const GridIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px' }}>

  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  enable-background="new 0 0 25 25" 
  height="38px" 
  viewBox="0 0 25 25" 
  width="38px" 
  fill="currentColor">
    <g><rect /></g><g><g><g><path d="M3,3v8h8V3H3z M9,9H5V5h4V9z M3,13v8h8v-8H3z M9,19H5v-4h4V19z M13,3v8h8V3H13z M19,9h-4V5h4V9z M13,13v8h8v-8H13z M19,19h-4v-4h4V19z" /></g></g></g></svg>
    </div>
);

export default GridIcon;