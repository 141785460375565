import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import RoutesComponent from 'components/RoutesComponent';
import { AuthProvider } from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import HtmlLanguageManager from './components/HtmlLanguageManager';

declare module '@mui/material/styles' {
  interface Palette {
    yellow: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    }
  }
  interface PaletteOptions {
    yellow?: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    }
    sky?: {
        main: string;
        light: string;
        dark: string;
        contrastText: string;
      },
      orange?: {
        main: string;
        light?: string;
        dark?: string;
        contrastText: string;
      },
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {

  const { language } = useContext(UseLanguageContext);

  useEffect(() => {
    document.documentElement.setAttribute('dir', language == 'en' ? 'ltr' : 'rtl');
  }, [language]);


  const rtlCache = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const ltrCache = createCache({
    key: 'mui',
  });

  const THEME = createTheme({
    direction: language == 'en' ? 'ltr' : 'rtl',
    typography: {
      fontFamily: ['Assistant', 'sans-serif'].join(','),
      fontSize: 16,
      htmlFontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      h1: {
        fontSize: "36px",
        fontWeight: "800",
        color: "#0F65EF",
      },
      h2: {
        fontSize: "24px",
        fontWeight: "bold"
      },
      h3: {
        fontSize: "18px",
        fontWeight: "bold"
      },
      h4: {
        fontSize: "20px",
      },
      subtitle1: {
        fontSize: "14px",
        fontWeight: "400"
      },

    },
    palette: {

      primary: {
        main: '#0E66F0',
        light: '#0E66F0',
        dark: '#0052D3',

      },
      secondary: {
        main: '#C6CCD4',
        light: '#E0E7E9',
        dark: '#92969D',
      },
      yellow: {
        main: '#E3D026',
        light: '#E9DB5D',
        dark: '#A29415',
        contrastText: 'white',

      },
      sky: {
        main: '#3799FF',
        light: '#A3D4FF',
        dark: '#0073E6',
        contrastText: 'white',

      },
      orange: {
        main: '#F04F25',
        light: '#FFA04D',
        dark: '#D03B15',
        contrastText: 'white',

      },
    },

    components: {
      MuiTypography: {
        styleOverrides: {
          root: "16px"
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '16px',
          },
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '16px',
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontColor: "black !important",
            fontSize: '14px !important',

          },
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none', // Suppression de l'ombre portée
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            marginBottom: 10
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: '410px',
          },
          root: {
            wordWrap: 'break-word',
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#C6CCD4', // Couleur par défaut
            '&.Mui-checked': {
              color: '#0E66F0', // Couleur lorsque la case est cochée
              '&:hover': {
                color: '#0E66F0', // Garder la couleur principale au survol même si coché
              },
            },
            '&:hover': {
            },
            '&.Mui-disabled': {
              color: '#B0BEC5', // Couleur pour la case désactivée
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginTop: '0px',
            marginBottom: '0px',
           /*  '& .MuiInputBase-root': {
              height: '50px',
            }, */
            '& .MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2766F0',
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
            },
            '& .MuiInputLabel-root': {
              '&:hover': {
                color: '#2766F0',
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            marginTop: "0px",
            fontSize: "16px"

          },
          input: {
            fontSize: "16px"
          }
        }
      },

      MuiInputLabel: {
        styleOverrides: {

          /*   shrink: {
              transform: 'translate(0px, -6px) scale(1)',
              transformOrigin: 'top left',
              background: '#ffffff',
            }, */
        },
      },
      /*    MuiOutlinedInput: {
           defaultProps: {
             sx: {
               fontSize: "18px",
             }
           }
         }, */
      /*   MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: '18px',
            },
          },
        }, */
      MuiDialogContent: {
        styleOverrides: {
          root: {
            width: '410px',
            minWidth: 'auto',
            textAlign: language === 'he' ? 'right' : 'left',
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            minWidth: 'max-content', // S'adapte à la largeur du contenu
            padding: '10px', // Ajoutez un padding si nécessaire
            '& .MuiMenuItem-root': {
              paddingLeft: '20px', // Ajustez le padding des éléments du menu
              paddingRight: '20px', // Ajustez le padding des éléments du menu
            },

            // Ajouté pour définir une largeur minimale de 100px
          },
          list: {
            paddingX: "20px",
          },
          paper: {

            boxShadow: 'none', // Suppression de l'ombre portée
            border: "#C4C4C480 0.5px solid",

          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 'inherit',
            padding: 8,
            '&:hover': {
              backgroundColor: '#E5E5E5',
              opacity: 1,
              color: "black",
              fontSmooth: 'never',


            },
            fontSmooth: 'never',

          },

        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            marginTop: "10px",
            padding: "0px !important",
          },
          selectRoot: {
            order: -1,
            color: '#000000DE', // Couleur convertie en hexadécimal
            fontSize: '14px',
          },
          actions: {
            order: 1,
            color: '#000000DE', // Couleur convertie en hexadécimal

            fontSize: '16px',
          },
          select: {
            color: '#000000DE', // Couleur convertie en hexadécimal

            fontSize: '14px',

          },
          displayedRows: {
            order: 1,
            color: '#000000DE', // Couleur convertie en hexadécimal
            fontSize: '14px',
          },
          selectLabel: {
            order: -1,
            color: '#000000DE', // Couleur convertie en hexadécimal
            fontSize: '14px',
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)', // Ombre plus claire et équilibrée             
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: '50px',
          },
          select: {
            padding: '14px 14px',
          },
        },
      },
    },
  });

  /* 
  components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              textAlign: language === 'he' ? 'right' : 'left', // Alignement du texte
            },
          },
        },
    
        MuiMenuItem: {
          styleOverrides: {
            // Ajout de la règle pour la taille de la police des éléments de menu
            root: {
              fontSize: '16px',
              '&:not(:last-child)': {
                borderBottom: "0.5px solid #C4C4C480"
              }
            },
   
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2766F0',
                },
              },
              '& .MuiInputLabel-root': {
                '&:hover': {
                  color: '#2766F0',
                },
              },
              '& .MuiFormHelperText-root': {
                fontSize: '18px',
              },
              '& .MuiInputLabel': {
                fontSize: '18px',
              },
   
              '&:not(.MuiAutocomplete-root)': {
                '& .MuiFormLabel-root.MuiInputLabel-root': {
                  transformOrigin: language === 'en' ? 'top left' : 'top right',
                  left: language === 'en' ? 'inherit' : 'auto',
                  right: language === 'en' ? 'auto' : '0',
                  textAlign: language === 'en' ? 'left' : 'right',
                },
                '& .MuiInputBase-root': {
                  direction: language === 'en' ? 'ltr' : 'rtl',
                },
                '& .MuiInputBase-input': {
                  textAlign: language === 'en' ? 'left' : 'right',
                },
                '& .MuiInputLabel-shrink': {
                  transformOrigin: language === 'en' ? 'top left' : 'top right',
                },
                '& .MuiFormHelperText-root': {
                  textAlign: language === 'en' ? 'left' : 'right',
                },
                ...(language !== 'en' && {
                  '& .MuiFormLabel-root': {
                    left: 'auto',
                    right: 'auto',
                    //transformOrigin: 'top right',
                    textAlign: 'right',
                    marginRight: '25px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    textAlign: 'right',
                  },
                }),
              },
            },
          },
        },
   
   
        MuiAutocomplete: {
          styleOverrides: {
            endAdornment: {
              ...(language !== 'en' &&
                { left: '8px' }
              )
            },
            root: {
   
              '& .MuiInputBase-root': {
                width: '100%',
                paddingRight: "15px !important"
   
   
              },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            list: {
              padding: 0
            },
            paper: {
              boxShadow: 'none', // Suppression de l'ombre portée
              border: "#C4C4C480 0.5px solid"
   
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              '&:hover': {
                color: '#0E66F0', // Couleur principale au survol
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: '#C6CCD4', // Couleur par défaut
              '&.Mui-checked': {
                color: '#0E66F0', // Couleur lorsque la case est cochée
                '&:hover': {
                  color: '#0E66F0', // Garder la couleur principale au survol même si coché
                },
              },
              '&:hover': {
              },
              '&.Mui-disabled': {
                color: '#B0BEC5', // Couleur pour la case désactivée
              },
            },
          },
        },
     
   
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: '18px',
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              direction: language === 'en' ? 'ltr' : 'rtl',
   
              display: 'flex',
              alignItems: 'center',
              width: '90vw',
              marginTop: '1.5rem',
              paddingLeft: 0,
            },
            toolbar: {
   
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            },
            selectRoot: {
              order: 1,
              background: ' #FFFFFF',
              border: '1px solid rgba(196, 196, 196, 0.5)',
            },
            spacer: {
              flex: 'none',
              display: 'none',
            },
            actions: {
   
              order: 3,
              margin: 0,
            },
            displayedRows: {
              order: 2,
            },
            selectLabel: {
              order: 1,
   
              flex: language === 'en' ? '3 1 auto' : "auto",
              textAlign: 'end',
            },
          },
        },
   
        MuiTableContainer: {
          styleOverrides: {
            root: {
              boxShadow: 'none',
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              width: '410px',
            },
            root: {
              wordWrap: 'break-word',
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              width: '410px',
              minWidth: 'auto',
              textAlign: language === 'he' ? 'right' : 'left',
            },
          },
        },
   
      },
  */

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={THEME}>

          <CacheProvider value={language == "he" ? rtlCache : ltrCache}>
            <HtmlLanguageManager />
            <RoutesComponent />
          </CacheProvider>
        </ThemeProvider>

      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
