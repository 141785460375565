import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Menu,
  MenuItem
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import UseAuthContext from 'context/AuthContext';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { IProjectListResponse } from 'utils/contracts';
import { formatClimat, formatType } from 'utils/Formatter';
import { ellipsis } from 'utils/utils';
import ButtonIcon from './ButtonIcon';
import CustomTableCell from './CustomTableCell';
import Restrict from './Restrict';

const ProjectTileListView = ({ ...props }: any) => {
  const { name, climate, revisions, type, updatedAt, id, handleDeleteProject } =
    props.data as IProjectListResponse;
  const { t } = useTranslation();
  const formattedClimate = t(formatClimat(climate));
  const formattedType = t(formatType(type));
  const { enqueueSnackbar } = useSnackbar();
  const { businessId } = useParams();
  const { role } = useContext(UseAuthContext);
  const isAdmin = role && role.toLowerCase() === USER_ROLES.ADMIN;
  const navigate = useNavigate();


  const editProjecthandler = async (e: any, projectId: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (businessId) {
      navigate(`/edit-project/${projectId}/${businessId}`);
    } else {
      navigate(`/edit-project/${projectId}`);
    }
  };

  const clickHandler = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAdmin && businessId) {
      navigate(`/admin/business/${businessId}/project/${id}/board`);
    } else {
      navigate(`/project/${id}/board`);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      sx={{ cursor: "pointer" }}

    >


      <CustomTableCell width={"230px"} component='th' scope='row' title={name} onClick={(e) => clickHandler(e)}>
        {ellipsis(name, 30)}
        {/* <Box sx={{ gap: "10px", display: "flex" }} >
        
          <div>
            {image_url && (
              <img width='30px' height='30px' src={image_url} alt='' />
            )}
            </div> 
          <div>
            
          </div>
          
        </Box>
        */}
      </CustomTableCell>
      <CustomTableCell width={"230px"} onClick={(e) => clickHandler(e)}>
        {ellipsis(formattedType, 25)}
      </CustomTableCell>
      <CustomTableCell width={"230px"} onClick={(e) => clickHandler(e)}>
        {ellipsis(formattedClimate, 25)}
      </CustomTableCell>
      <CustomTableCell onClick={(e) => clickHandler(e)} width={"230px"}>
        {revisions.length}
      </CustomTableCell>
      <CustomTableCell width={"130px"} onClick={(e) => clickHandler(e)}>

        {moment.utc(updatedAt).local().format('DD/MM/YY')}
        {' '}

        {moment.utc(updatedAt).local().format('HH:mm')}

      </CustomTableCell>
      <CustomTableCell >
        <ButtonIcon
          isActive={open}
          onClick={handleClick}
        //sx={{ visibility: hover ? 'visible' : 'hidden' }} // Use visibility to keep space
        >
          <MoreHorizIcon />
        </ButtonIcon>

        <Menu
          sx={{ fontSize: '14px !important' }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        /*      anchorOrigin={{
               vertical: 'top',
               horizontal: language === 'en' ? 'left' : 'right',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: language === 'en' ? 'left' : 'right',
   
             }} */
        >
          <MenuItem onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editProjecthandler(e, id);
          }}>
            {t('PROJECT_MENU_EDIT')}
          </MenuItem>
          <MenuItem onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            clickHandler(e);
          }}>
            {t('PROJECT_MENU_VIEW')}
          </MenuItem>
          <Restrict forbidden={['viewer']}>
            <MenuItem onClick={(e) => {
              handleDeleteProject(e, id);
            }}>
              {t('PROJECT_MENU_DELETE')}
            </MenuItem>
          </Restrict>
        </Menu>
      </CustomTableCell>
    </TableRow>
  );
};

export default ProjectTileListView;