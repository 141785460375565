import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';

/* export const GetPricings = async (): Promise<any> => {
  return await axiosInstance.get(API_PATHS.GET_PRICINGS);
}; */

export const getPricingPlans = async (): Promise<any> => {
  return await axiosInstance.post(API_PATHS.GET_PRICINGS);
};
