import { IconButton, useTheme } from '@mui/material';


const ButtonIconSvg = ({ isActive,isDisabled, children,onClick }: { isActive?: boolean; isDisabled?: boolean; onClick?: (e:any) => void,children: React.ReactNode  }) => {
	const theme = useTheme();

	return (
		<IconButton
			disabled={isDisabled}
			onClick={onClick}
			sx={{
				p:"2px",
				color: isActive && !isDisabled ? theme.palette.primary.main : theme.palette.secondary.main, // Couleur de l'icône
				'&:hover': {
					color: isActive ? theme.palette.primary.main : 'black', // Couleur de l'icône au survol
					//backgroundColor: isActive ? 'none' : '#F3F3F3',
					
				},
			}}
		>
			{children} 
			</IconButton>
	);
};

export default ButtonIconSvg;