import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import BadgeDiscount from 'assets/BadgeDiscount';
import ButtonCustom from 'components/ButtonCustom';
import UseAuthContext from 'context/AuthContext';
import LanguageContext from 'context/LanguageContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getPricingPlans } from 'services/pricing-service';
import shortid from 'shortid';
import { clientConfig } from 'utils/utils';
const PricingPage = () => {
	const { user, isAuthenticated, organisationId } = useContext(UseAuthContext)
	const { t } = useTranslation()
	const {language} = useContext(LanguageContext)
	const navigate = useNavigate()
	const theme = useTheme()
	const isRTL = theme.direction === 'rtl';

	const { data: pricing, isLoading } = useQuery({
		queryKey: ['pricingPlans'],
		queryFn: getPricingPlans,
	});


	if (isLoading || !pricing) {
		return <div>Loading...</div>;
	}

	return (
		<Box mt={"20px"} display={'flex'} gap={0} flexDirection={'column'} justifyContent={"center"} alignItems={"center"}>
			<Typography variant="h1" fontSize={"36px"} align="center" >
				{t("PRICING_HEADER_TITLE")}
			</Typography>
			<Typography fontSize={"16px"}  mt={"14px"} align="center" >
				{t("PRICING_HEADER_DESCRIPTION_1")} <br />
				{t("PRICING_HEADER_DESCRIPTION_2")}
			</Typography>
			<Grid mt={"28px"} container 
				gap={2} justifyContent="center"  sx={{ width: { xs: "50%", md: "105%" } }}>
				{pricing.data.filter((item) => item.name !== "Single Credit").reverse().map((plan, index) => (
					<Box display={'flex'} gap={"0px"} mt={""} key={plan.name} sx={{ border: plan.name === "Best" ? '2px solid #0F65EF' : '1px solid #C6CCD4', textAlign: 'center', borderRadius: "10px", position: 'relative' }} component="div" justifyContent={"center"} width={"377px"} height={"532px"}>
						{plan.percentage_discount > 0 && (
							<Box sx={{ position: 'absolute', top: '-2px', ...(language == "he" ? { left: '24px' } : { right: '24px' }) }}>
								{useMediaQuery(theme.breakpoints.down('md')) ? <BadgeDiscount 
									width={55}
									height={55}
									percentage={plan.percentage_discount} 
								/> : <BadgeDiscount 
									width={74}
									height={76}
									percentage={plan.percentage_discount} 
								/>}
							</Box>
						)}
						<Box display={"flex"} flexDirection={"column"} width={"258px"} >
							<Box mt={"30px"} >
								<Typography fontWeight={"bold"} variant={'h1'} sx={{ color: plan.name === "Best" ? '' : 'black' }}>{plan.name}</Typography>

								<Typography mt={"20px"} fontSize={"16px"}>
									<span style={{ fontSize: "34px", fontWeight: "600" }}>{plan.pricing.monthly} </span> ₪ {t("PRICING_MONTHLY")}
								</Typography>
								<Typography mt={"-5px"} fontSize={"16px"} dir="ltr">{t("PRICING_YEARLY")}  {plan.pricing.annual} ₪ {t("PRICING_YEARLY_BEFORE_VAT")}</Typography>
								<ButtonCustom
									sx={{ width: "258px", height: "50px", marginTop: "25px", fontWeight: "bold" }}
									variant={plan.name === "Best" ? "contained" : "outlined"}
									color="primary"
									onClick={() => {
										if (isAuthenticated) {
											const newTransactionId = shortid.generate();
											window.open(`${plan.payment_link}?ReturnData=${clientConfig.pricingEnvironment}|${organisationId}|${newTransactionId}|${user?.id}`, "_blank")
										}
										else {
											navigate("/login")
										}
									}}
								>
									{t("PRICING_PURCHASE")} {plan.credits} {t("PRICING_CREDITS")}
								</ButtonCustom>

								{/* Table pour m2_pricing */}
								<Box
									sx={{
										marginTop: "20px",
										display: 'flex',
										flexDirection: 'column',
										width: '100%'
									}}
								>
									{/* Header */}
									<Grid container dir={isRTL ? 'rtl' : 'ltr'}  sx={{ borderBottom: "1px solid #C6CCD4", height: "41px" }} >
									<Grid item xs={4} sx={{
											fontWeight: '600',
											textAlign: 'center',
											p: 0,
											fontSize: "14px",
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											{t("PRICING_TABLE_M2")}
										</Grid>
										<Grid item xs={4} sx={{
											fontWeight: '600',
											textAlign: 'center',
											p: 0,
											fontSize: "14px",

											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											{t("PRICING_TABLE_CREDITS")}
										</Grid>
										<Grid item xs={4} sx={{
											fontWeight: '600',
											fontSize: "14px",
											textAlign: 'center',
											p: 0,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											₪
										</Grid>
									
									</Grid>

									{/* Rows */}
									{plan.m2_pricing.map((m2, idx) => (
										<Grid container key={m2.m2_range} 
										dir={isRTL ? 'ltr' : 'rtl'} 
										sx={{
											height: "41px",
											borderBottom: idx === plan.m2_pricing.length - 1 ? 'none' : '1px solid #C6CCD4' 
										}}>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
												{m2.Ils_Credit}
											</Grid>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
												{m2.credits}
											</Grid>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}} dir='ltr'>
												{m2.m2_range}
											</Grid>
										</Grid>
									))}
								</Box>
							</Box>
							{/* <Box marginTop={"29px"}>
								<Typography 
								color={plan.name == "Best" ? '#0F65EF' : 'black'}
								 sx={{  fontWeight:"bold", fontSize:"16px" }}>עד {plan.buildings} מבנים סטנדרטים</Typography>

							</Box>
 */}
						</Box>
					</Box>
				))}
			</Grid>
			<Box display={'flex'} justifyContent={"center"} flexDirection={"column"} flexWrap={"wrap"} mt={"30px"} >
				{language === "he" ? <Typography fontSize={16} textAlign={"center"}>{t("PRICING_FOOTER_DESCRIPTION_1")}
					<span style={{ marginLeft: '4px', marginRight: '4px' }}>
						<Link
							sx={{ color: "blue", textDecoration: 'none', '&:hover': { textDecoration: 'none', color: 'blue' } }}
							onClick={() => {
								if (isAuthenticated) {
									const newTransactionId = shortid.generate();
									window.open(`https://secure.cardcom.solutions/e/xr27?ReturnData=${clientConfig.pricingEnvironment}|${user?.organizationId}|${newTransactionId}|${user?.id}`, "_blank");
								}
								else {
									navigate("/login")
								}
							}}
						>
							{t("PRICING_FOOTER_SINGLE_CREDITS")}
						</Link>
					</span>
				</Typography> :  <Typography fontSize={16} textAlign={"center"}>{t("PRICING_FOOTER_DESCRIPTION_1")}
					<span style={{ marginLeft: '4px', marginRight: '4px' }}>
						<Link
							sx={{ color: "blue", textDecoration: 'none', '&:hover': { textDecoration: 'none', color: 'blue' } }}
							onClick={() => {
								if (isAuthenticated) {
									const newTransactionId = shortid.generate();
									window.open(`https://secure.cardcom.solutions/e/xr27?ReturnData=${clientConfig.pricingEnvironment}|${user?.organizationId}|${newTransactionId}|${user?.id}`, "_blank");
								}
								else {
									navigate("/login")
								}
							}}
						>
							{t("PRICING_FOOTER_SINGLE_CREDITS")}
						</Link>
					</span>
				</Typography>}
				<Typography fontSize={"16px"} textAlign={"center"}>
					{t("PRICING_FOOTER_DESCRIPTION_2")}
					<span style={{ marginLeft: '4px', marginRight: '4px' }}>
						<Link
							sx={{ color: "blue", textDecoration: 'none', '&:hover': { textDecoration: 'none', color: 'blue' } }}
							target="_blank"
							href="https://docs.google.com/forms/d/e/1FAIpQLScO0p3T9PzT02Fy_bPT1z7mVJ_94dEvLo_sxql85gL8zEUVJg/viewform"
							rel="noopener">
							{t("PRICING_FOOTER_BANK_TRANSFER")}
						</Link>
					</span>
				</Typography>
			</Box>
			<Box display={'flex'} justifyContent={"space-around"} alignItems={"center"} mt={"20px"} width={"100%"} >
				<Box display={"flex"} gap={2} alignItems={"center"} width={"50%"} justifyContent={"flex-start"}>
					<img src="/static/img/favicons/cal.png" alt="CAL" style={{ height: "26px" }} />
					<img src="/static/img/favicons/isracard.png" alt="Isracard" style={{ height: "26px" }} />
					<img src="/static/img/favicons/leumi.png" alt="Leumi" style={{ height: "26px" }} />
					<img src="/static/img/favicons/mastercard.png" alt="Mastercard" style={{ height: "26px" }} />
					<img src="/static/img/favicons/visa.png" alt="Visa" style={{ height: "26px" }} />
				</Box>
				<Box display={"flex"} gap={2} alignItems={"center"} width={"50%"} justifyContent={"flex-end"} >
					<img src="/static/img/favicons/cardcom.png" alt="Cardcom" style={{ height: "60px" }} />
					<img src="/static/img/favicons/pci.png" alt="PCI" style={{ height: "30px" }} />
				</Box>

			</Box>
		</Box>
	);
};


export default PricingPage;