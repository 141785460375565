import { Box, Button, ButtonProps } from '@mui/material';
import React from 'react';

interface ButtonCustomProps extends ButtonProps {
	startIcon?: React.ReactNode;
	loading?: boolean;
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({ children, size, startIcon, loading = false, disabled, ...props }) => {
	const getSizeStyles = (size: ButtonProps['size']) => {
		switch (size) {
			case 'small':
				return {
					width: '130px',
					height: '36px',
					padding: '0px',
					fontSize: '14px',
				};
			case 'large':
				return {
					width: '190px',
					height: '60px',
					fontSize: '20px',
				};
			case 'medium':
			default:
				return {
					width: '176px',
					height: '50px',
					fontSize: '18px',
				};
		}
	};

	return (
		<Button
			{...props}
			disabled={loading || disabled}
			sx={{
				...getSizeStyles(size),
				boxShadow: 'none',
				'&:hover': {
					boxShadow: 'none',
				},
				position: 'relative',
				...props.sx,
			}}
		>
		 <Box display={'flex'} gap={1}>
				{startIcon && <Box sx={{ display:"flex", alignItems:"center"}}>{startIcon}</Box>}
				{children}
			</Box>
		
		</Button>
	);
};

export default ButtonCustom;