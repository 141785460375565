
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Box, Grid, Typography } from '@mui/material';
import ProjectPlaceHolder from 'assets/Project_PlaceHolder.svg';
import ButtonIconText from 'components/ButtonIconText';
import Restrict from 'components/Restrict';
import Spinner from 'components/Spinner';
import UseAuthContext from 'context/AuthContext';
import { useRevision } from 'hooks/useRevision';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getProjectById } from 'services/project-service';
import { getFeatures } from 'services/revisions';
import { USER_ROLES } from 'utils/constants';
import { formatClimat } from 'utils/Formatter';
import RevisionFilter from './RevisionFilter';
import RevisionGrid from './RevisionGrid';
import RevisionList from './RevisionList';

type ViewType = 'List' | 'Table' | 'Graph';

const RevisionsPage = () => {
  const [view, setView] = useState<ViewType>('List');
  const [isGraphView, setIsGraphView] = useState(false);
  const { projectId, businessId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role: authorization } = useContext(UseAuthContext);
  const isAdmin = authorization === USER_ROLES.ADMIN;
  const { data , isLoading, refetch } = useRevision(projectId);
  const { data: projectData , isLoading: isProjectLoading } = useQuery(
    ['GetProjectByID', projectId],
    async () => await getProjectById(projectId),
    {
      enabled: !!projectId,
    }
  );
  const { data: features, isLoading: isFeaturesLoading } = useQuery('features', getFeatures);
  const [isGraphAllowed, setIsGraphAllowed] = useState(features?.graphAllowed > projectData?.createdAt);



  const formattedClimate = t(formatClimat(projectData?.climate));

  const changeView = (view: ViewType) => {
    if (view === "Graph") {
      setIsGraphView(!isGraphView);
    } else {
      setView(view);
      setIsGraphView(false);
    }
  };

  if (isProjectLoading && projectData) {
    return <Spinner />;
  }

  const editProjecthandler = async (e: any, projectId: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (businessId) {
      navigate(`/edit-project/${projectId}/${businessId}`);
    } else {
      navigate(`/edit-project/${projectId}`);
    }
  };

  useEffect(() => {
    setIsGraphAllowed(features?.graphAllowed < projectData?.createdAt)
  }, [projectData, features])

  return (
    <Box>
      <Grid  >
        <Grid container item justifyContent='space-between' flexDirection='row' alignItems='center' sx={{ minHeight: "144px",  mb:"22px" }} flexWrap={"wrap"} gap={"1rem"} >
          <Box display={'flex'} alignItems={"center"} gap={"2rem"} >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <div className={"revision-container"} onClick={(e) => editProjecthandler(e, projectData?.id)}>
                <img
                  src={projectData?.image_url || ProjectPlaceHolder}
                  alt={projectData?.image_url}
                  className="image-container"
                />
                <div className="revision-overlay">
                  <div className="revision-overlay-content">
                    <div>
                      <ModeEditOutlineIcon sx={{ fontSize: "36px" }} />
                    </div>
                    <div style={{}}>
                      {t('PROJECT_EDIT_MENU')}
                    </div>
                  </div>
                </div>
              </div>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height:"100px", minWidth:"100px" }}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={"space-between"} sx={{height: "58px"}}>
                <Typography variant='h2' noWrap textOverflow={"ellipsis"} whiteSpace={'nowrap'} title={projectData?.name} sx={{ marginTop: "-2px", padding: 0 }}>
                  <Link
                    to={`${isAdmin ? `/admin/business/${businessId}/projects` : `/`
                      }`}
                  >
                    {projectData?.name}
                  </Link>
                </Typography>
                <Typography variant='h4'>
                {formattedClimate}
                </Typography>
                </Box>
              
              </Box>
            </Box>
            <Restrict forbidden={['viewer']}>
                <Box>
                  <ButtonIconText
                    icon={<div style={{ fontSize: "40px", display: "flex", alignItems: "center" }}><AddRoundedIcon fontSize='inherit' /></div>}
                    color='primary'
                    type='submit'
                    variant='contained'
                    size='large'
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (isAdmin) {
                        navigate(
                          `/admin/business/${businessId}/project/${projectId}/add-revision`
                        );
                      } else {
                        navigate(`/project/${projectId}/add-revision`);
                      }
                    }}
                    disableElevation={true}
                  >
                    {t('REVISIONS_ADD_BUTTON')}
                  </ButtonIconText>
                </Box>
              </Restrict>
          </Box>
          <Grid item alignContent={"center"} alignSelf="center">
            <RevisionFilter changeView={changeView} view={view} isGraphView={isGraphView} isGraphAllowed={isGraphAllowed} />
          </Grid>
        </Grid>
      </Grid>
      {view === 'List' && <RevisionList  data={data} isLoading={isLoading} refetch={refetch} />}
      {view === 'Table' && <RevisionGrid isGraphView={isGraphView} revisionData={data} />}
    </Box>
  );
};

export default RevisionsPage;