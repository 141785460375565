import { MenuItem } from '@mui/material';
import UseAuthContext from 'context/AuthContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { AdminMenus, PrivateMenus, PublicMenus } from 'utils/utils';

const HeaderMenu = ({ isForHeader, isRowDirection = false, onClose = () => {} }: any) => {
  const { isAuthenticated, role } = React.useContext(UseAuthContext);
  const { t } = useTranslation();
  const location = useLocation();
  const data = matchPath('/edit/business/:businessId', location.pathname);
  const isAdminRole = role && role.toLowerCase() === USER_ROLES.ADMIN;
  const isContactPage = location.pathname === '/contact' ;
  const isSignUpByInvitePage = location.pathname === '/signUpByInvite' ;
  const isSignUpPage = location.pathname === '/signup' ;
  const isPricingPage = location.pathname === '/pricing' ;

  const BindMenus = () => {
    let menus: any =
      role && role.toLowerCase() === USER_ROLES.ADMIN
        ? [...AdminMenus]
        : isAuthenticated
        ? [...PrivateMenus, ...PublicMenus]
        : PublicMenus;

    if (isForHeader) {
      menus = menus.sort((a: any, b: any) => a.order - b.order);
      menus = menus.filter((menu: any) => !menu.removeFromHeader);
      if (isContactPage && !isAuthenticated ||isSignUpPage ||isSignUpByInvitePage || isPricingPage && !isAuthenticated) {
        menus = menus.filter((menu: any) => menu.name !== 'MENU_CONTACT' && menu.name == '"MENU_HOW_IT_WORKS"' );
      } 
    } else {
      menus = PublicMenus.sort(
        (a: any, b: any) => a.footerOrder - b.footerOrder
      );
      if (isContactPage && !isAuthenticated || isPricingPage && !isAuthenticated) {
        menus = menus.filter((menu: any) => menu.name !== 'MENU_HOW_IT_WORKS');
      }
      if (isAdminRole) {
        menus = menus.filter((menu: any) => menu.name !== 'MENU_CONTACT' && menu.name !== 'MENU_PRICING' && menu.name !== 'MENU_HOW_IT_WORKS');
      }
    }
   /*  if (data && !isForHeader) {
      if (menus.filter((menu: any) => menu.name === 'Projects').length === 0) {
        menus = menus.filter((menu: any) => menu.name !== 'Projects');
        menus.push({
          name: 'Projects',
          path: `admin/business/${params?.businessId ?? ''}/projects`,
        });
      }
    } */

    return menus.map((menu: any) => {
      if ((isSignUpPage || isSignUpByInvitePage) && menu.name === 'MENU_HOW_IT_WORKS') {
        return null;
      }

      const handleNavigation = () => {
        if (typeof onClose === 'function') {
          onClose();
        }
      };

      if (isRowDirection) {
        return (
          <MenuItem key={menu.name} style={{ display: 'flex', flexDirection: 'row' }}>
            {menu.external ? (
              <a href={menu.path} target='_blank' rel='noreferrer' onClick={handleNavigation}>
                {t(menu.name)}
              </a>
            ) : (
              <Link to={menu.path} onClick={handleNavigation}>
                {t(menu.name)}
              </Link>
            )}
          </MenuItem>
        );
      }

      return menu.external ? 
      
      (
        <a href={menu.path} key={menu.name} target='_blank' rel='noreferrer' onClick={handleNavigation}>
          {t(menu.name)}
        </a>
      ) : (
        <Link to={menu.path} key={menu.name} onClick={handleNavigation}>
          {t(menu.name)}
        </Link>
      );
    }).filter(Boolean);
  };
  return <>{BindMenus()}</>;
};

export default HeaderMenu;
