import { Box, CircularProgress } from '@mui/material';

const SplashScreen = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        minHeight: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default SplashScreen;
