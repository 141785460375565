import { SearchOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear'; // Import du logo pour effacer
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import ButtonIcon from 'components/ButtonIcon';
import ButtonIconText from 'components/ButtonIconText';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const OrganisationFilter = ({ handleSearch, handleDownloadBusinessData }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const handleChangeSearchText = (e: any) => {
    
    setValue(e.target.value);
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    setValue("");
    handleSearch("");
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight:"144px",
        mb:"22px",
        flexWrap: 'wrap',

      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
         flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box display={'flex'} alignItems={"center"}  gap={"2rem"} flexWrap={"wrap"}>
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '31px',
              color: '#2F3B49',
            }}
          >
            {t('ADMIN_DASHBOARD_TITLE')}
          </Typography>
        </Box>
        <Box >
          <ButtonIconText
            color='primary'
            disableElevation={true}
            type='submit'
            variant='contained'
            size='large'
            onClick={(e: any) => {
              e.preventDefault();
              navigate('/admin/add-business');
            }}
      >
            {t('ADMIN_DASHBOARD_ADD_ORGANISATION')}
      </ButtonIconText>
         
        </Box>
        <Box>
          <TextField
            autoComplete='off'
            id='standard-bare'
            size='small'
            fullWidth={true}
            variant='outlined'
            placeholder={t('ADMIN_ORGANISATION_SEARCH_PLACEHOLDER')}
            sx={{
              minWidth: "280px",
              '& .MuiInputBase-root': {
                WebkitFontSmoothing: 'auto', 
                padding: "4px", 
              
              },
              '& .MuiInputBase-inputAdornedStart': {
                padding: 0, // Enlever le padding
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {value ? (
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton disabled>
                      <SearchOutlined />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              sx: { height: '50px' } // Augmenter la hauteur de l'input
            }}
            value={value}
            onChange={handleChangeSearchText}
          />
        </Box>
        </Box>
       
        <Box display={'flex'} alignItems={"center"}>
          <ButtonIcon
            onClick={handleDownloadBusinessData}>
            <FileDownloadOutlinedIcon fontSize='large' />
          </ButtonIcon>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganisationFilter;