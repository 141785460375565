import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import ProjectTileListView from 'components/ProjectTileListView';
import { useTranslation } from 'react-i18next';

let DEFAULT_ORDER = 'desc',
  DEFAULT_ORDER_BY = 'updatedAt';

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&.Mui-active": {
    color: theme.palette.primary.main,
  },
  "&.MuiTableSortLabel-root": {
    fontWeight: "bold",
    color: "black",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  ".MuiTableSortLabel-icon:hover": {
    color: "black",

    backgroundColor: "#dcdcdc",
    borderRadius: "50%",
    border: "1px solid #D3D3D3",
  },
  ".MuiTableSortLabel-icon": {
    border: "1px solid #D3D3D3",
    color: "black",
    borderRadius: "50%",
  },
  "&.Mui-active .MuiTableSortLabel-icon": {
    border: "none",
  },

  "&.Mui-active, .MuiTableSortLabel-icon:hover": {
    color: theme.palette.primary.main,

    backgroundColor: "none !important",

  }
}));

const ProjectListView = ({ 
  data,
  pagination,
  page,
  pageSize,
  sortBy,
  sortOrder,
  onSort,
  onPageChange,
  onPageSizeChange,
  refetchingData,
  deleteProjecthandler 
}: any) => {
  const { t } = useTranslation();

  // Fonction utilitaire pour obtenir le nombre de révisions
  const getRevisionsCount = (item: any) => {
    return Array.isArray(item.revisions) ? item.revisions.length : 0;
  };

  // Prétraiter les données pour le tri des révisions
  const getSortedData = () => {
    if (sortBy === 'revisions') {
      return [...data].sort((a, b) => {
        const countA = getRevisionsCount(a);
        const countB = getRevisionsCount(b);
        return sortOrder === 'asc' ? countA - countB : countB - countA;
      });
    }
    return data;
  };

  const sortedData = getSortedData();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          {/*  <TableCell align="center" sx={{width: "70px", minWidth: "70px"}} sortDirection={orderBy === "index" ? order : false}>
              <StyledTableSortLabel
                active={orderBy === "index"}
                direction={orderBy === "index" ? order : "asc"}
                onClick={createSortHandler("index")}
              >
                {t("REVISION_INDEX_COLUMN")}
                {orderBy === "index" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </TableCell> */}
          <CustomTableFontCell>
            <StyledTableSortLabel
              active={sortBy === "name"}
              direction={sortBy === "name" ? sortOrder : "asc"}
              onClick={() => onSort("name")}
            >
              {t("PROJECT_LIST_TABLE_NAME")}
              {sortBy === "name" ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </CustomTableFontCell>

          <TableCell
            sortDirection={sortBy === "type" ? sortOrder : false}
          >
            <StyledTableSortLabel
              active={sortBy === "type"}
              direction={sortBy === "type" ? sortOrder : "asc"}
              onClick={() => onSort("type")}
            >
              {t("PROJECT_LIST_TABLE_TYPE")}
              {sortBy === "type" ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
          <TableCell
            sortDirection={sortBy === "climate" ? sortOrder : false}
          >
            <StyledTableSortLabel
              active={sortBy === "climate"}
              direction={sortBy === "climate" ? sortOrder : "asc"}
              onClick={() => onSort("climate")}
            >
              {t("PROJECT_LIST_TABLE_CLIMATE")}
              {sortBy === "climate" ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
          <TableCell
            sortDirection={sortBy === "revisions" ? sortOrder : false}
          >
            <StyledTableSortLabel
              active={sortBy === "revisions"}
              direction={sortBy === "revisions" ? sortOrder : "asc"}
              onClick={() => onSort("revisions")}
            >
              {t("PROJECT_LIST_TABLE_ALTERNATIVES")}
              {sortBy === "revisions" ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
          <TableCell
            sortDirection={sortBy === "updatedAt" ? sortOrder : false}
          >
            <StyledTableSortLabel
              active={sortBy === "updatedAt"}
              direction={sortBy === "updatedAt" ? sortOrder : "asc"}
              onClick={() => onSort("updatedAt")}
            >
              {t("PROJECT_LIST_TABLE_DATE")}
              {sortBy === "updatedAt" ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableHead>
        <TableBody>
          {sortedData.map((item: any) => (
            <ProjectTileListView
              data={{ ...item, handleDeleteProject: deleteProjecthandler }}
              key={item.id}
              refetch={refetchingData}
            />
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={pagination?.total}
        rowsPerPageOptions={[20]}

        page={page - 1}
        onPageChange={(_, newPage) => onPageChange(newPage + 1)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => {
          const newPageSize = parseInt(e.target.value, 10);
          onPageSizeChange(newPageSize);
        }}
        labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
        labelDisplayedRows={({ from, to, count }) => 
          t('MUI_PAGINATION_RANGE', { 
            from: from + 1, 
            to: Math.min(to, count), 
            count 
          })}
      />
    </TableContainer>
  );
};

export default ProjectListView;
