import React from 'react';

const ListIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width="50" 
		height="50" 
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	
		{...props}
	>
		<rect x="14" y="13" width="22" height="3" rx="1.5" fill="currentColor"/>
		<rect x="14" y="23" width="22" height="3" rx="1.5" fill="currentColor"/>
		<rect x="14" y="33" width="22" height="3" rx="1.5" fill="currentColor"/>
	</svg>
);

export default ListIcon;