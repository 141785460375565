import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
export default function Report_1045(props: any) {
  let { pass } = props;
  if (pass !== undefined) {
    return pass === true ? (
      <CheckIcon htmlColor='#4fb947' fontSize='small' />
    ) : (
      <ClearIcon htmlColor='#b92126' fontSize='small' />
    );
  } else {
    return null;
  }
}
