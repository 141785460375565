import { useQuery } from 'react-query';
import { getrevisions } from 'services/revisions';

interface Revision {
  id: string;
  status: string;
}

export const useRevision = (projectId: string) => {
  return useQuery({
    queryKey: ['GetAllRevisions', projectId],
    queryFn: async () => {
      try {
        const  data  = await getrevisions(projectId);
        return data;
      } catch (error) {
        console.error('Erreur lors de la récupération des révisions:', error);
        throw error;
      }
    },
    refetchInterval: (data) => {
      if (!data) return 5000;
      const hasNonSuccessItem = data.some((item: Revision) => item.status !== 'success' && item.status !== 'failed');
      return hasNonSuccessItem ? 10000 : false;
    },
    retry: 3,
    enabled: !!projectId,
  });
};