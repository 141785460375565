import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IORGRegistrationRequest } from './../utils/contracts';

export const signupOrganisation = async (
  signupData: IORGRegistrationRequest
) => {
  return await axiosInstance.post(API_PATHS.ORG_REGISTRATION, signupData);
};

export const GetUserOrganization = async (id: string) => {
  try {
    const response = await axiosInstance.get(`${API_PATHS.GET_ORGANISATION}${id}`, {
      headers: {
        'Cache-Control': 'max-age=3600', // Cache pour 1 heure
      },
    });
    
    // Si l'image est dans la réponse, on peut la mettre en cache aussi
    if (response.data?.image_url) {
      const cacheKey = `org-image-${id}`;
      localStorage.setItem(cacheKey, response.data.image_url);
    }
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUserOrganizationShort = async (orgID: string): Promise<any> => {
  const {data} = await axiosInstance.get(API_PATHS.GET_ORGANISATION + orgID + '/short');
  return data;
};

export const getVersion = async (): Promise<any> => {
  const { data: version } = await axiosInstance.post(API_PATHS.GET_VERSION);
  return version;
};
