interface BadgeDiscountProps extends React.SVGProps<SVGSVGElement> {
  percentage?: number;
  width?: number | string;
  height?: number | string;
}

const BadgeDiscount: React.FC<BadgeDiscountProps> = ({ percentage = 30, width = 74, height = 76, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 74 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_2601_4171)">
      <path d="M4 2H70V70L37 57.127L4 70V2Z" fill="#0E66F0"/>
    </g>
    <text x="37" y="35" textAnchor="middle" fontSize="22" fontWeight="bold" fill="white" style={{direction: 'ltr'}}>
      -{percentage}%
    </text>
    <defs>
      <filter id="filter0_d_2601_4171" x="0" y="0" width="74" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2601_4171"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2601_4171" result="shape"/>
      </filter>
    </defs>
  </svg>
);

export default BadgeDiscount;
