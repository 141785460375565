import React from 'react';

const DragIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
<svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.6129" cy="6.06452" r="1.06452" fill="#C6CCD5"/>
<circle cx="9.06452" cy="6.06452" r="1.06452" fill="#C6CCD5"/>
<circle cx="12.6129" cy="11.0323" r="1.06452" fill="#C6CCD5"/>
<circle cx="9.06452" cy="11.0323" r="1.06452" fill="#C6CCD5"/>
<circle cx="12.6129" cy="16" r="1.06452" fill="#C6CCD5"/>
<circle cx="9.06452" cy="16" r="1.06452" fill="#C6CCD5"/>
<circle cx="12.6129" cy="20.9677" r="1.06452" fill="#C6CCD5"/>
<circle cx="9.06452" cy="20.9677" r="1.06452" fill="#C6CCD5"/>
<circle cx="12.6129" cy="25.9355" r="1.06452" fill="#C6CCD5"/>
<circle cx="9.06452" cy="25.9355" r="1.06452" fill="#C6CCD5"/>
</svg>

);

export default DragIcon;
