export interface IUserLoginRequest {
  email: string;
  password: string;
}

export interface IAdminLoginRequest {
  password: string;
}

export interface IORGRegistrationRequest {
  company_name: string;
  company_address: string;
  company_phone: string;
  company_email?: string;
}

export interface IUpdatePrivateArea {
  email: string;
  name: string;
  phone: string;
  role: string;
}


export interface IUpdateLanguage {
  language: string;
}


export interface IOrganisationUpdate {
  id?: any;
  image_url?: string;
  preview?: any;
}

export interface IInviteUser {
  email: string;
  authorization: string;
  language?: string;
}

export interface IForgotRequest {
  email: string;
}
export interface IUserLoginResponse {
  success?: boolean;
  token: string;
  profile?: Profile;
}

export interface IProjectListResponse {
  id?: string;
  name?: string;
  index?: number;
  revisions?: any[];
  address?: string;
  type?: string;
  climate?: string;
  city?: string;
  createdBy?: string;
  bg_color?: string;
  image_url?: string;
  updatedAt?: string;
  refetch?: any;
  handleDeleteProject?: any;
}

export interface IProjectAddRequest {
  id?: any;
  name?: string;
  type?: string;
  city?: string;
  terrain?: string;
  climate?: string;
  partition?: string;
  construction?: string;
  version?: string;
  gush?: string;
  address?: string;
  bg_color?: string;
  image_url?: string;
  preview?: any;
  organizationId?: any;
  publicId?: any;
}

export interface IOrganisationResponse {
  id: string;
  company_name: string;
  updatedAt: string;
  image_url: string;
  company_phone: string;
  company_address: string;
  company_email: string;
  members: Member[];
  invites: Invites[];
  transactions?: any;
  hasTrial?: boolean;
  totalCredits?: number;
  totalPoints?: number;
}

export interface Invites {
  id: string;
  email: string;
  invitedByName: string;
  authorization: string;
}

export interface Member {
  id: string;
  email: string;
  name: string;
  updatedAt: string;
  authorization: string;
  status: string;
}

export interface Profile {
  organizations: any;
  user: User;
}

export interface User {
    id?: string
    email?: string
    memberOf?: any[]
    name: string
    role: string
    organizationId?: string
    phone: any
}

export interface Auth {
  exp: any;
  id: string;
  token: string;
}

export interface IAdminTopUp {
  ProdQuantity?: number;
  ProductDesc?: string;
  TotalPoints?: string | undefined;
  OrganizationId?: string;
  TransactionId?: string;
  ProductID?: string;
  suminfull?: string;
}

export interface IErrorResponse {
  ServerErrorCode?: IErrorObject[];
  GeneralErrorCode?: string;
  actualError?: any;
}

export interface IResetPasswordRequest {
    newPassword: string
    confirmPassword:string
    token: string
}

export interface IErrorObject {
  field: string;
  code: string;
}

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

export enum RevisionStatus {
  CHECKED = 'checked',
  FAILED = 'failed',
  VALIDATING = 'validating',
  RESOLVING = 'resolving',
  PROCESSING = 'processing',
  PENDING = 'pending',
  SUCCESS = 'success',
  TICKET_ON_GOING = 'ongoing',
  TICKET_OPEN = 'open',
}

export interface IRevisionCreate {
  file?: any;
  notes?: string;
  h_report_density?: string;
  h_report_percentage?: number;
  h_report_uvalue?: number;
  simulator?: string;
}

export interface IRevisionEditFile {
  files?: Record<string, FileSchema>;
}

export interface FileSchema {
  name: string;
  url: string;
  publicId: string;
}

export interface ITicketData {
  user?: ITicketUser;
  ticket?: Ticket;
  revision?: Revision;
  project?: ProjectOrOrganization;
  organization?: ProjectOrOrganization;
}
export interface ITicketUser {
  id?: string;
  name?: string;
  email?: string;
  phone?: string | null;
}
export interface Ticket {
  id?: string;
  status?: string;
  updatedAt?: string;
}
export interface Revision {
  id?: string;
  status?: string;
}
export interface ProjectOrOrganization {
  id?: string;
  name?: string;
  image_url?: string
}

export type Order = 'asc' | 'desc';


export interface IPictureData {
  id: string , 
  type: string , 
  file:string , 
  destination: string , 
  size: string
}
